import React, { useState,useEffect }  from 'react';
import Icon, {MenuUnfoldOutlined,DownOutlined,MenuFoldOutlined,HolderOutlined} from '@ant-design/icons';
import { Layout, Menu,Dropdown, Button, message,Space,Badge,Breadcrumb } from 'antd';
import {Route, Routes, Navigate, useHistory,NavLink,useNavigate} from "react-router-dom"
// import { withRouter} from "react-router-dom"
import './styles.less';
import './index.css';
import menuInfo from '../../menuRoute/index';
import { getUserinfo, userLoginout} from '../login/service';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import { useLocation } from 'react-router'
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 


const PersonSvg = () => (
  <svg t="1672718332518" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29424" width="16" height="16"><path d="M512.0512 563.8912c143.0016 0 259.08736-118.55872 259.08736-264.87296 0-146.31936-116.08576-264.87808-259.09248-264.87808s-259.08736 119.1424-259.08736 264.87808c0 145.73568 116.08064 264.87296 259.08736 264.87296z m494.24384 324.77696l-18.54976-86.8864c-17.95072-83.29216-86.1696-155.264-170.53184-167.24992l-41.88672-5.9904a1885.87008 1885.87008 0 0 0-525.96224 0l-41.8816 5.9904c-84.37248 11.9808-153.18016 83.95776-170.53696 167.24992l-18.54976 86.8864c-5.38112 25.1648 0.59904 50.3296 16.75776 70.10816 16.1536 19.77344 39.49056 31.16032 65.21856 31.16032h824.54528c25.12896 0 49.06496-11.38688 65.21856-31.16032 15.55968-19.77344 21.53984-44.94336 16.15872-70.10816z" p-id="29425" fill="currentColor"></path></svg>);
  
const PersonIcon = props => (
  <Icon component={PersonSvg} {...props} />
  );

const ExitSvg = () => (
  <svg t="1672717209068" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13765" width="16" height="16"><path d="M951.737186 488.212224 802.424532 301.56936c-7.222495-9.027607-18.034748-14.011108-29.157064-14.011108-4.131087 0-8.300037 0.688685-12.349259 2.106987-14.957667 5.246491-24.970718 19.371186-24.970718 35.223223l0 111.98756-298.631448 0c-41.232077 0-74.656327 33.42425-74.656327 74.656327 0 41.2331 33.42425 74.656327 74.656327 74.656327l298.631448 0 0 111.98756c0 15.852036 10.013051 29.977755 24.970718 35.223223 4.049223 1.424442 8.218172 2.108011 12.349259 2.108011 11.123338 0 21.934568-4.978385 29.157064-14.013155l149.311631-186.643887C962.64563 521.221012 962.64563 501.848803 951.737186 488.212224L951.737186 488.212224zM586.628698 810.162774 362.66074 810.162774l-74.656327 0 0-0.011256c-0.199545 0-0.393973 0.011256-0.587378 0.011256-40.906665 0-74.076112-33.42425-74.076112-74.656327l0-74.656327 0-298.631448 0-74.656327 0.011256 0c0-0.199545-0.011256-0.393973-0.011256-0.587378 0-40.906665 33.429367-74.076112 74.66349-74.076112l74.656327 0 223.967958 0c41.2331 0 74.66349-33.422204 74.66349-74.656327 0-41.232077-33.429367-74.656327-74.66349-74.656327L213.340923 63.586201c-82.459037 0-149.311631 66.853617-149.311631 149.311631l0 597.262896c0 82.4662 66.853617 149.311631 149.311631 149.311631l373.286752 0c41.2331 0 74.66349-33.422204 74.66349-74.656327C661.291165 843.586001 627.861798 810.162774 586.628698 810.162774L586.628698 810.162774zM586.628698 810.162774" fill="currentColor" p-id="13766"></path></svg>);
  
const ExitIcon = props => (
  <Icon component={ExitSvg} {...props} />
  );

// function getItem (list){
//     let arr = [];
//     list.map((item)=>{
//         if(item.hideInMenu){
//             return
//         }else{
//             let obj = {};
//             obj['key'] = item.path;
//             obj['label'] = item.title;
//             obj['icon'] = item.icon;
//             if(item.children){
//                 obj['children']= getItem(item.children)
//             }
//             arr.push(obj)
//         } 
//     })
//     return arr;
// }
// const items = getItem(menuList)


const scrheight = document.body.clientHeight;
// console.log(scrheight)

const defaultPanes = [
  {
    label: `Tab 1`,
    // children: `Content of Tab Pane ${index + 1}`,
    key: 1,
  },
  {
    label: `Tab 2`,
    // children: `Content of Tab Pane ${index + 1}`,
    key: 2,
  },
];

const Admin =(props)=>{

  const navigate = useNavigate();
  const [curMenu, setCurMenu] = useState("dashboard");
  const [curTitle, setCurTitle] = useState(["控制台"]);
  const [collapsed, setCollapsed] = useState(false);
  const [menuList, setMenuList] = useState([]);
  // const [openKeys, setOpenKeys] = useState([]);
  // const onOpenChange = (keys) => {
  //   console.log(keys, keys)
  //   // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  //   // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //   //   setOpenKeys(keys);
  //   // } else {
  //   //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   // }
  // };

  const location = useLocation();
  console.log('location:------------------ ', location)

  const token = localStorage.getItem('token');
  var currentUserinfo = localStorageGet('UserInfo');
  console.log("currentUserinfo---------", currentUserinfo);

  if(!token || !currentUserinfo){
    // if(location.pathname.substr(0, 6) == "/admin")
      window.location.href=PathBASE+'#/admin/login';
    // else
    //   window.location.href='/#/login';
  }
  if(location.pathname == "/admin" || location.pathname == "/admin/")
      window.location.href=PathBASE+'#/admin/dashboard';

  // const currentUserinfo = JSON.parse(decrypt(UserInfo));
  // console.log("currentUserinfo---------", currentUserinfo);
  // if(currentUserinfo.menuPerm.lengh == 0)
  //   window.location.href='/#/login';

  useEffect(() => {
    if(token && currentUserinfo)
      getMyMenu();
  }, []);

  useEffect(() => {

    setCurMenu(location.pathname);
    const title = getTitle(menuList,location.pathname);
    setCurTitle(title);
  }, [menuList, location.pathname]);

  const getMyMenu=async()=>{
    try {
      await getApiData('A', 'A_getMenu', {}).then((res) => {
      console.log('A_getMenu-res-----------------', res);
      var flag = checkLogin('A', res.data)
      if (flag) {
          setMenuList(res.data.d.list);
      }
      });
    } catch (error) {
        console.log('catch-error2 ---------- ', error)
        // return false;
    }
  }

  const getTitle=(list,path)=>{
    // console.log('list----------',list)
    // console.log('path----------',path)
    var titleList = [];
    //得到当前请求路径
    for(let i=0;i<list.length;i++){
      let item = list[i];
      // console.log('item----------',item)
      console.log(path.substring(0, item.path.length))
      if(path == item.path){
        titleList.push(item.name);
        break;
      }
      else if(path.substring(0, item.path.length) == item.path){
        titleList.push(item.name);
        if(item.sub){
          for(let j=0;j<item.sub.length;j++){
            let item_c = item.sub[j];
            console.log('item_c----------',item_c)
            if(path == item_c.path){
              titleList.push(item_c.name);
              break;
            }
          }
        }
      }
    }
      // console.log('titleList------',titleList)
    return titleList
  }

  
const getRoutesNodes=(menuList)=>{
  return menuList.map(item=>{        
      if(!item.sub || item.sub.length==0){
          return <Route path={item.path} key={item.path+1}  element={menuInfo[item.path].component}></Route>
      }else{
          return  getRoutesNodes(item.sub)
      } 
  })
}

const getMenuaNodes=(menuList)=>{
  // console.log('menuList ---------------- ', menuList)
  // console.log(menuInfo)
// console.log("menuList - currentUserinfo---------", currentUserinfo);

  return menuList.map(item=>{
    // console.log('item----------', item);
    //如果当前用户有item权限，才需要显示对应菜单项
    // if(item.show ){
    // if(item.show && (currentUserinfo.userType==99 || currentUserinfo.menuPerm.includes(item.path))){
      if(!item.sub || item.sub.length == 0){
              return(<Menu.Item key={item.path} icon={menuInfo[item.path].icon}>
                  <NavLink to={item.path}> {item.name}</NavLink>                      
                  </Menu.Item>)
      }else{
          return(
              <SubMenu key={item.path} icon={menuInfo[item.path].icon} title={item.name}>
                  {getMenuaNodes(item.sub)}
              </SubMenu>
          )
      }
    // }
  })
}


  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClick = ({ key }) => {
    // message.info(`Click on item ${key}`);
    if(key == "accmanage")
      window.location.href=PathBASE+'#/maccount';
    else if(key == "logout")
      Logout();
  };
  const items = [
    // {
    //   label: <Space align="center" size={12}><PersonIcon />账号管理</Space>,
    //   key: 'accmanage',
    //   // icon: <PersonIcon />
    // },
    {
      label: <Space align="center" size={12}><ExitIcon />退出登录</Space>,
      key: 'logout',
      // icon: <ExitIcon />
    },
  ];

  const Logout = (e) => {
    localStorage.setItem('token',"");
    localStorageSet("UserInfo", {});
    window.location.href=PathBASE+'#/admin/login';
  }

  return (
    <Layout>
    <Header 
    style={{
        position: 'fixed',
        top: 0,
        zIndex: 800,
        width: '100%',
        height:50,
        background:"rgb(152,100,201)",
        color:"white"
      }}
       className="header" theme="light" >
      <div style={{margin: '-10px -25px',height: "100%",position:"absolute"}}>
        <img src={[require("../../img/tbilogo.png")]} height={30}/>
      <Button
        type="text"
        onClick={toggleCollapsed}
        style={{
          color:"white",
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      {/* <Tabs
        hideAdd
        // onChange={onChange}
        // activeKey={activeKey}
        type="editable-card"
        // onEdit={onEdit}
        items={tabItems}
      /> */}
      </div>
      <div  className="top-right" >
      {/* <Button type="normal"  shape="round" className='msgbutton' icon={<BellOutlined style={{ fontSize: '20px'}}/>} ></Button> */}
      <Dropdown className='userSuper'
        menu={{
          items,
          onClick,
          style:{borderRadius:10,textAlign:"center"}
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          {/* <Space align="center" style={{color:"black"}}> */}
            <img src={[require("../../img/avatar.png")]} width={25} style={{borderRadius:"50%"}} />
            {/* <Avatar size="middle" icon={<UserOutlined />} /> */}
            <span style={{marginLeft:10,marginRight:15}}>{currentUserinfo?.account}</span>
            <DownOutlined style={{fontSize:10}} />    
          {/* </Space> */}
        </a>
      </Dropdown>
    </div>
    </Header>
    <Layout theme="light">
      <Sider width={200} className="site-layout-background"  collapsed={collapsed} collapsedWidth={60}
        style={{
          marginTop: 50,
          height: scrheight-50,
          overflowY: "scroll",
          // minHeight: scrheight-50,
          // maxHeight: scrheight-50,
          position: "fixed"
        //   padding: '10px 10px',background:'#f1f6fa',
        //   overflow: 'auto',
        //   // height: '90vh',
        //   position: 'fixed',
        //   left: 0,
        //   top: 65,
        //   bottom: 5,
        }}
        >
        <Space direction="horizontal" style={{paddingTop:10, paddingBottom:10, color:'white',float:"left",marginLeft:collapsed?10:15}} size={10}>
        <img src={[require("../../img/avatar.png")]} width={collapsed?25:45} style={{borderRadius:"50%"}} />
        <Space direction="vertical" size={1} style={{display:collapsed?'none':'block',textAlign:"left"}}>
          <span style={{fontWeight:550}}>{currentUserinfo?.account}</span>
          <span><Badge status="success" style={{marginRight:3,width:13,height:13}} />在线</span>
        </Space>
        </Space>
        <Menu 
          theme="dark" 
          mode="inline" 
          defaultSelectedKeys={[curMenu]} 
          selectedKeys={[curMenu]} 
          // openKeys={openKeys}
          // onOpenChange={onOpenChange} 
          style={{fontSize:13,minHeight:"100%"}}
        >
          {getMenuaNodes(menuList, {})}
        </Menu>
      </Sider>
      <Layout
        style={{
          marginTop:60, marginRight:10, marginBottom:10, marginLeft:collapsed?60:210,background:'#f1f6fa',
        }}
      >
        <Space direction="horizontal" style={{textAlign:"left",padding:"10px 0"}}>
        <HolderOutlined />
        <Breadcrumb >
            {curTitle.map(item => (
            // console.log('111111111111111111111-',item),
              <Breadcrumb.Item key={item} style={{cursor:'pointer',color:'#777'}} >{item}</Breadcrumb.Item>
                
              ))}
          </Breadcrumb>
        </Space>
        <Content
          className="site-layout-background"
          style={{
            padding: 0,
            margin: 0,
            // minHeight: scrheight-60,
          }}
        >
         <div
            className="site-layout-background"
            style={{
              padding: 0,
              textAlign: 'left',fontSize:13
            }}
          >
                <Routes>
                    {
                        getRoutesNodes(menuList)
                    }
                </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  </Layout>

  )
}

export default Admin