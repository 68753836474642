import React, { useState, useRef,useEffect } from 'react';
import { SmileOutlined,DeleteOutlined } from '@ant-design/icons';
import {
  ProForm,
  EditableProTable,
} from '@ant-design/pro-components';
import { Button, Card,Input,Form,Row,Col, Space, message } from 'antd';

export default (props) => {
  const {label, name, columns, initdata} = props;
  const [editableKeys, setEditableRowKeys] = useState([]);

  // const [editableKeys, setEditableRowKeys] = useState(() =>
  //   defaultData.map((item) => item.id),
  // );

  useEffect(() => {
    if(initdata){
      setEditableRowKeys(() =>
        initdata.map((item) => item.id)
      );
    }

  }, [initdata]);
  
  return (

          <ProForm.Item
            label={label}
            name={name}
            // initialValue={defaultData}
            trigger="onValuesChange"
            style={{
              paddingTop:20,
              paddingBottom:20,
              marginTop: 50,
              marginBottom: 20,
              backgroundColor:"rgb(246,246,247)"
            }}
          >
            <EditableProTable
              rowKey="id"
              toolBarRender={false}
              columns={columns}
              recordCreatorProps={{
                newRecordType: 'dataSource',
                position: "bottom",
                creatorButtonText: '追加',
                record: () => ({
                  id: Date.now(),
                  // addonBefore: 'ccccccc',
                  // decs: 'testdesc',
                }),
              }}
              editable={{
                type: 'multiple',
                editableKeys: editableKeys,
                onChange: setEditableRowKeys,
                actionRender: (row, _, dom) => {
                  return [dom.delete];
                },
              }}
            />
          </ProForm.Item>

  );
};