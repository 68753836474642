import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Cascader,Switch } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;



const App =(props)=>{
    const {showModal, closeModal, rowInfo, catList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    const [levellist, setLevellist] = useState([]);
    const [loading, setLoading] = useState(false);

    // var catoption = [
    //   {value:"0", label:"初评"},
    //   {value:"1", label:"复评"},
    //   {value:"2", label:"终评"},
    // ];
    // var judgetype = [
    //   {value:"0", label:"A类"},
    //   {value:"1", label:"B类"},
    // ];
    // for(let v of catList){
    //   catoption.push({value:v.cid, label:(v.sep?" ":"")+v.sep+v.name});
    // }
    // console.log('catoption --------------------------- ',catoption)


    useEffect(() => {
      if(rowInfo.id == undefined){
        form.resetFields();
      } else {
        for(let key in rowInfo)
          form.setFieldValue(key, rowInfo[key])
      }
      if(showModal){
        getLevellist();
      }

    }, [showModal]);
    

    // 获取评选阶段列表
    const getLevellist = ()=>{
      if(showModal){
        try {
          getApiData('A', 'A_getJudgeLevel', {}).then((res) => {
            console.log('A_getJudgeLevel-res-----------------', res);
            var flag = checkLogin('A', res.data)
            var level = [{value:"", label:"--请选择--"}];
            if (flag) {
              for(let k in res.data.d.levellist)
                level.push({value:k, label:res.data.d.levellist[k]});
              setLevellist(level);
            }
          });
        } catch (error) {
          
          // return false;
        }

      }
    }

    // 提交
    const onFinish = async(value)=>{
        console.log(value);
        let fun = "";
        if(rowInfo.sid == undefined){
          fun = "A_createScoreStandard";
          value.judgetype = 2;
        } else {
          fun = "A_editScorestandard";
          value.sid = rowInfo.sid;
        }

        try {
          setLoading(true);
          await getApiData('A', fun, value).then((res) => {
            setLoading(false);
            console.log(fun+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:5,offset:2}}
            size="small"
            // initialValues={rowInfo}
        >
            <Form.Item
                label="投票项名称"
                name="review"
                rules={[
                  {required: true, message: '请输入投票项名称',},
                ]}
            >
            <Input placeholder="投票项名称" />
            </Form.Item>
            <Form.Item
                label="评选阶段"
                name="level"
                rules={[
                  {required: true, message: '请选择评选阶段',},
                ]}
            >
              <Select
                  // placeholder="请选择选择栏目"
                  // onChange={onProdChange}
                  defaultValue={""}
                  // allowClear
                  // style={{width:"50%"}}
                  options={levellist}
              />
            </Form.Item>
            <Form.Item
                label="计分方式"
                name="score"
            >
            <Input placeholder="计分方式" />
            </Form.Item>
            <Form.Item
                label="指标阐述"
                name="desc"
            >
            <Input placeholder="指标阐述" />
            </Form.Item>
            <Form.Item
                label="展示状态"
                name="status"
            >
              <Switch defaultChecked={true} size="large"  />

            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
