import React , { useState, useEffect, useRef, useContext }from 'react'
import UEditor from '../../../components/UEditor';
import {Modal,Form,Button,Input,message,Select,Radio,Divider,Upload,Progress,Cascader,Space,DatePicker,Checkbox,List,Image,ConfigProvider } from 'antd'
import Icon,{UploadOutlined,DeleteOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

import "../../../enter/addtc/index.css"
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
const { RangePicker } = DatePicker;

const {Item} = Form
const { Option } = Select;

function getDateStr(AddDayCount) {
    var dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
    var year = dd.getFullYear();
    var mon = dd.getMonth() + 1; //获取当前月份的日期
    var day = dd.getDate();
    return year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day);
  }
  
// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const screenHeight = document.body.clientHeight;

const App =(props)=>{
    const {showModal, closeModal, rowInfo} = props;
    const [template, setTemplate] = useState({base:[], offer:[]});
    const [catOptions, setCatOptions] = useState([]);
    const [gconfig,setGConfig] = useState({footer:{}});
    console.log('rowInfo --------- ', rowInfo)
    const ueRef = useRef(null)

    useEffect(() => {
      if(showModal){
        getConfig();
        if(rowInfo.tid){
          setTemplate(rowInfo.template);
          try {
            getApiData('A', 'A_catelogList', {pid:rowInfo.catid}).then((res) => {
              console.log('A_catelogList-res-----------------', res);
              var flag = checkLogin('A', res.data)
              if (flag) {
                setCatOptions([res.data.d.list]);
              }
            });
          } catch (error) {
            
            // return false;
          }
        }
      } else {
        setTemplate({base:[], offer:[]});
        setCatOptions([]);
      }
    }, [showModal, rowInfo]);

    const getConfig = async()=>{
        try {
            return await getApiData('U', 'U_getConfig', {}).then((res) => {
              console.log('U_getConfig-res-----------------', res);
              var flag = checkLogin('U', res.data)
              if (flag) {
                setGConfig(res.data.d.config)
              }
            });
          } catch (error) {
            
            return false;
          }
      }
    

    //编辑器的宽度
    const [config] = useState({
        initialFrameWidth: '100%',
        initialFrameHeight: 400
    })
    //编辑器的默认值
    const [initData] = useState('')
    //富文本失焦就触发setContent函数设置表单的content内容
    const setContent = (e)=>{
        // form.setFieldsValue({
        //     content: ueRef.current.getUEContent()
        // })
    }
    const [initData2] = useState('')
    //富文本失焦就触发setContent函数设置表单的content内容
    const setContent2 = (e)=>{
        // form.setFieldsValue({
        //     content2: ueRef2.current.getUEContent()
        // })
    }
    
    const CustomEmptyList = () => (
        // 这里面就是我们自己定义的空状态
        <span></span>
    )

    
// const getMenuaNodes=(menuList, currentUserinfo)=>{
//     // console.log(menuList)
//     // console.log("menuList - currentUserinfo---------", currentUserinfo);
  
//     return menuList.map(item=>{
//         // console.log('item----------', item);
//         //如果当前用户有item权限，才需要显示对应菜单项
//         // if(item.show ){
//         // if(item.show && (currentUserinfo.userType==99 || currentUserinfo.menuPerm.includes(item.path))){
//           if(!item.children){
//                   return(<Menu.Item key={item.path} icon={item.icon}>
//                       <NavLink to={item.path}> {item.title}</NavLink>                      
//                       </Menu.Item>)
//           }else{
//               return(
//                   <SubMenu key={item.path} icon={item.icon} title={item.title}>
//                       {getMenuaNodes(item.children, currentUserinfo)}
//                   </SubMenu>
//               )
//           }
//         // }
//     })
//   }
  
    
    const showItems=(key)=>{
        if(!template)
            return;
        if(!template.hasOwnProperty(key))
            return;

        return template[key].map(item=>{
            // console.log('111111111111111111111111111111 - ', item)
            var desc = '';
            if(item.desc)
                desc = (<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"110%"}}>*{item.desc}</div>);
            if(item.type == 'upload')
                return (
                    <Item name={item.name}  label={item.title} >
                        <Upload >
                            <Button icon={<UploadOutlined />} type="primary" shape='round'  style={{color:"white",backgroundColor: "rgb(231,76,60)",borderColor: "rgb(231,76,60)"}} >上传</Button>
                        </Upload>
                        {/* <Progress percent={percent} style={{width:'80%',top:-30,left:100,display:percentShow?'block':'none'}}/> */}
                        {/* <img src={imageUrl.logo} height={100} style={{display: imageShow.logo==true?'block':'none'}} /> */}
                        {desc}
                    </Item>
                )
            else if(item.type == 'text')
                return (
                    <Item name={item.name}  label={item.title} >
                        <Input placeholder="24字以内" allowClear/>
                        {desc}
                    </Item>
                )
            else if(item.type == 'select_category')
                return (
                    <Item name={item.name}  label={item.title} >
                        <Cascader 
                            options={catOptions} allowClear={false}
                            fieldNames={{
                                label: 'name',
                                value: 'cid',
                                children: 'children',
                                }}
                            placeholder="请选择"
                        />
                        {desc}
                    </Item>
                )
            else if(item.type == 'select_company_category')
                return (
                    <Item name={item.name}  label={item.title} >
                        <Select
                            // placeholder="请选择选择栏目"
                            // onChange={onGenderChange}
                            defaultValue={"1"}
                            // allowClear
                            // style={{width:"50%"}}
                        >
                            <Option value="1">品牌公司组</Option>
                            <Option value="2">营销公司组</Option>
                            <Option value="3">媒体公司组</Option>
                        </Select>
                        {desc}
                    </Item>
                )
            else if(item.type == 'range')
                return (
                    <Item name={item.name}  label={item.title} >
                        <Space direction="vertical"  style={{width:'80%',}} >
                        <RangePicker   allowClear={false}  locale={locale}
                            defaultValue={[moment(getDateStr(-7)), moment(getDateStr(-1))]}
                            // ranges={{
                            //     '今 日': [moment(), moment()],
                            //     '昨 日': [moment(getDateStr(-1)), moment(getDateStr(-1))],
                            //     '7 天': [moment(getDateStr(-7)), moment(getDateStr(-1))],
                            //     '14 天': [moment(getDateStr(-14)), moment(getDateStr(-1))],
                            //     '30 天':[moment(getDateStr(-30)), moment(getDateStr(-1))]
                            // }}
                            // onChange={this.getChangeTime}  
                            />
                        </Space>
                        {desc}
                    </Item>
                )
            else if(item.type == 'select_custom'){
                var selectdata = [];
                if(typeof(item.selectdata)=="string")
                    selectdata = item.selectdata.split("\n");
                else
                    selectdata = item.selectdata;
                return (
                    <Item name={item.name}  label={item.title} >
                        <Select
                            placeholder="请选择"
                            // onChange={onGenderChange}
                            // defaultValue={""}
                            // allowClear
                            // style={{width:"50%"}}
                        >
                            { Object.keys(selectdata).map(index=>{ return (<Option value={index}>{selectdata[index]}</Option>)})}
                        </Select>
                        {desc}
                    </Item>
                )

            }
            else if(item.type == 'editor')
                return (
                    <Item name={item.name}  label={item.title} initialValue={""}>
                        <UEditor id={item.name} ref={ueRef} config={config} show={showModal} initData={""} setContent={(e)=>setContent(e)}></UEditor>
                        {desc}
                    </Item>
                )
        })
    }
  
  return (
    <Modal 
      key="basicinfo"
      title={'预览'}
      open={showModal}
      width={"70%"}
      bodyStyle={{height:screenHeight*0.8,overflowY:"scroll"}}
      centered
      destroyOnClose={true}
      footer={[<Button  className="m-btn-white" onClick={closeModal}>
      关闭
      </Button> ]}
      onCancel={closeModal}
    >
          <div className="PublishArticle-wrap">
            <Form 
                style={{width:'90%', paddingTop:0}} 
                autoComplete="off" 
                // form={form} 
                // onFinish={onFinish}
                labelAlign={'left'}
                labelCol={{span:4}}
            >
                <div className="add_title">基本信息</div>
                <Divider className='add_divide' />
                {showItems("base")}

                <div className="add_title">作品描述</div>
                <Divider className='add_divide' />
                {showItems("offer")}

                <div className="add_title">上传附件</div>
                <Divider className='add_divide' />
                <div className='divfj'>
                    <div className='sbutt' style={{textAlign:"center"}}>上传附件</div>
                    <div className="divfj-x">
                        <div style={{borderLeft:0, position:"relative"}} className="divfj-xy" >
                        <div style={{width:"100%",textAlign:"center"}}>
                            <img src={[require("../../../../img/pdf.jpg")]} alt="" className='divfj-xy-1' />
                            </div>
                            <div className="divfj-xy-2">PDF</div>
                            <div className="divfj-xy-3">pdf格式</div>
                            <div className="divfj-xy-4">文件大小限制: 50MB</div>
                        </div>
                        <div className="divfj-xy" style={{position: "relative"}}>
                        <div style={{width:"100%",textAlign:"center"}}>
                            <img src={[require("../../../../img/video.jpg")]} alt="" className='divfj-xy-1' />
                            </div>
                            <div className="divfj-xy-2">视频</div>
                            <div className="divfj-xy-3">mp4格式</div>
                            <div className="divfj-xy-4">文件大小限制: 500MB</div>
                            <div className="divfj-xy-4">推荐尺寸：800PX*450PX</div>
                        </div>
                        <div className="divfj-xy" style={{position: "relative"}}>
                        <div style={{width:"100%",textAlign:"center"}}>
                            <img src={[require("../../../../img/picture.jpg")]} alt="" className='divfj-xy-1' />
                            </div>
                            <div className="divfj-xy-2">图片</div>
                            <div className="divfj-xy-3">jpg.jpeg.gif.png</div>
                            <div className="divfj-xy-4">文件大小限制: 5MB</div>
                            <div className="divfj-xy-4">推荐尺寸：800PX*450PX</div>
                        </div>
                    </div>
                </div>
                {/* <Progress percent={attpercent} strokeWidth={12} style={{width:'50%',top:-130,left:"27%",display:attpercentShow?'block':'none'}}/> */}
                <ConfigProvider renderEmpty={CustomEmptyList}>
                </ConfigProvider>
                <div>
                    <span className="hfoo">
                    <Space>
                    <Checkbox id="agree"></Checkbox> 我已阅读并同意<a target="_blank" href="/uploads/anlitijiaoxieyishu.pdf"><span style={{color: "#a4080f"}}>《用户作品提交协议书》</span></a>
                    </Space>
                    </span>
                </div>

                <Item >
                    <div className="group-sub">
                        <Space size={30}>
                        <Button type="primary" htmlType="submit" style={{width: 120,height:35,borderRadius: 5,backgroundColor: "#a4080f",borderColor: "#a4080f",}}>
                        保存为草稿
                        </Button>
                        <Button type="primary" htmlType="submit" style={{width: 120,height:35,borderRadius: 5,backgroundColor: "#a4080f",borderColor: "#a4080f",}}>
                        提交为正式作品
                        </Button>
                        <Button type="primary" style={{width: 120,height:35,borderRadius: 5,backgroundColor: "#c1c1c1",borderColor: "#c1c1c1",}} >
                        取消
                        </Button>
                        </Space>
                    </div>
                </Item>
                <div style={{fontSize:13, textAlign:"left"}}>您可免费提交≤{gconfig.freecasecnt}件作品参赛，若您提交作品超出{gconfig.freecasecnt}件，超出部分需支付{gconfig.casefee}元/件的报名费。</div>
            </Form>
        </div>


    </Modal>
  )
}
export default App
