import { EditOutlined,DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Popconfirm, message, Button, Space, Image } from 'antd';
import React, { useState,useRef }  from 'react';
import EditModel from './components/edit';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
  }

  const columns = [
    {
      title: '公司ID',
      dataIndex: 'id',
      // valueType: 'indexBorder',
      width: 80,
    },
    {
      title: '公司全称',
      dataIndex: 'cname',
      ellipsis:true,
      width: 190,
    },
    {
      title: '公司简称',
      dataIndex: 'sname',
      ellipsis:true,
      width: 150,
    },
    {
      title: '公司logo',
      dataIndex: 'logo',
      width: 80,
      search: false,
      render: (text) => {
        if (!text) {
          return <span> </span>;
        } else {
          return <Image src={text} width={35} height={35} />;
        }
      }, //这里放后台返回的图片的路径或者整个<img/>
    },
    {
      disable: true,
      title: '新/老客户',
      dataIndex: 'relation',
      width: 90,
      search: false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "1": {
          text: '新',
          status: 'Error',
          // disabled: true,
        },
        "2": {
          text: '老',
          status: 'Success',
        },
      },
    },
    {
      title: '联系人',
      dataIndex: 'contact',
      ellipsis:true,
      width: 80,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      ellipsis:true,
      width: 120,
    },
    {
      title: '职位',
      dataIndex: 'position',
      ellipsis:true,
      width: 100,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      ellipsis:true,
      width: 160,
    },
    {
      title: '地址',
      dataIndex: 'address',
      ellipsis:true,
      // width: 30,
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      width: 150,
      ellipsis:true,
    },
    {
      title: '跟进人',
      dataIndex: 'follow',
      width: 80,
      ellipsis:true,
    },
    {
      disable: true,
      title: '审核状态',
      dataIndex: 'status',
      width: 90,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "0": {
          text: '不通过',
          status: 'Default',
        },
        "1": {
          text: '通过',
          status: 'Success',
          // disabled: true,
        },
      },
    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {delComp(record.cid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </Popconfirm>
      // <a href={record.url} target="_blank" rel="noopener noreferrer" key="view">
        //   查看
        // </a>,
        // <TableDropdown
        //   key="actionGroup"
        //   onSelect={() => action?.reload()}
        //   menus={[
        //     { key: 'copy', name: '复制' },
        //     { key: 'delete', name: '删除' },
        //   ]}
        // />,
      ],
    },
  ];

  const delComp = (cid) =>{
    try {
      getApiData('A', 'A_delCompany', {companyid:cid}).then((res) => {
        console.log('A_delCompany-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  const downList = () =>{
    var cid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_companyDownload', {cid:cid}).then((res) => {
        console.log('A_companyDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }

  const downLogoList = () =>{
    try {
      getApiData('A', 'A_logoDownloadList', {type:"company"}).then((res) => {
        console.log('A_logoDownloadList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          if(res.data.d.list.length == 0){
            message.error("无公司logo");
          }
          downloadImages(res.data.d.list);
        }
      });
    } catch (error) {

      // return false;
    }
  }
      
    const downloadImages = async (listinfo) => {
      const zip = new JSZip();
      const imgPromises = listinfo.map(async (info, index) => {
        const response = await fetch(info.logo, {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }});
        const blob = await response.blob();
        zip.file(info.id+"_"+info.sname+info.logo.substring(info.logo.lastIndexOf('.'), info.logo.length), blob);
      });
      await Promise.all(imgPromises);
   
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "公司logo.zip");
      });
    };
   

   
  return (
    <div>
          
    <ProTable
      headerTitle="公司管理"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log(sort, filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_companyList', params).then((res) => {
            console.log('A_companyList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      editable={{
        type: 'multiple',
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      rowKey="cid"
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 400,
        },
      }}
      style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      scroll={{x:1700}}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:30
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={24}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
          </Space>
        );
      }}
      tableAlertOptionRender={() => {
        // return (
        //   <Space size={16}>
        //     <a><EyeOutlined />设为正常</a>
        //     <a><EyeInvisibleOutlined />设为隐藏</a>
        //   </Space>
        // );
      }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<DownloadOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            downList();
            // downloadExcel("案例管理", columns, tablesData, "", []);
          }}
          type="primary"
        >
          导出
        </Button>,
        <Button
          key="button"
          icon={<DownloadOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            downLogoList();
            // downloadExcel("案例管理", columns, tablesData, "", []);
          }}
          type="primary"
        >
          导出logo
        </Button>,
      ]}
    />

    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};