import React, { useState,useEffect,useRef }  from 'react';
import { Button, Dropdown, Space, Tag,Popconfirm,Switch,Tabs,message } from 'antd';
import { EllipsisOutlined, PlusOutlined,BarChartOutlined,OrderedListOutlined,EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import EditModel from './components/edit';
import EditModel_Vote from './components/edit_vote';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import './index.css'

const { TabPane } = Tabs;

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};


export default () => {
  const actionRef = useRef();
  const actionRef_vote = useRef();

  const [showModal, setShowModal] = useState(false);
  const [showModal_vote, setShowModal_vote] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [catList, setCatList] = useState([]);
  const [curTabPane, setCurTabPane] = useState(1);

  const closeModal = () =>{
    setShowModal(false);
    setShowModal_vote(false);
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      search:false,
      // valueType: 'indexBorder',
      width: 80,
      ellipsis: true,
    },
    {
      title: '评审类别',
      dataIndex: 'name',
      // ellipsis: true,
      render: (text, record) => {
        return <span style={{marginLeft:record.sep?6:0}}>{record.sep}{text}</span>;
      },
      // search:false

      // copyable: true,
      // tip: '标题过长会自动收缩',
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: '此项为必填项',
      //     },
      //   ],
      // },
    },
    {
      title: '评审指标',
      dataIndex: 'review',
      // width: 150,
      search:false,
    },
    {
      title: '指标阐述',
      dataIndex: 'desc',
      // width: 150,
      search:false,
      ellipsis: true,
    },
    {
      title: '阶段',
      dataIndex: 'levelname',
      search:false,
      width: 80,
    },
    {
      title: '最高分',
      dataIndex: 'max',
      search:false,
      width: 60,
    },
    {
      title: '最低分',
      dataIndex: 'min',
      search:false,
      width: 60,
    },
    {
      title: '权重',
      dataIndex: 'weight',
      search:false,
      width: 60,
    },
    {
      disable: true,
      title: '展示状态',
      dataIndex: 'status',
      width: 80,
      // search:false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      // valueType: 'select',
      // valueEnum: {
      //   "0": {
      //     text: '隐藏',
      //     status: 'Default',
      //   },
      //   "1": {
      //     text: '正常',
      //     status: 'Success',
      //     // disabled: true,
      //   },
      // },
      render: (text, record) => {
        // console.log('status----------',text,record)
        return <Switch defaultChecked={text=="1"?true:false} size="small" onClick={(checked)=>onChangeStatus(record.sid, checked, 1)} />;
      },

    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {onDelete(record.sid, 1)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </Popconfirm>
      
      ],
    },
  ];

  const columns_vote = [
    {
      title: '序号',
      dataIndex: 'id',
      search:false,
      // valueType: 'indexBorder',
      width: 80,
      ellipsis: true,
    },
    {
      title: '投票项名称',
      dataIndex: 'review',
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return <span style={{marginLeft:record.sep?6:0}}>{record.sep}{text}</span>;
      },
      // search:false

      // copyable: true,
      // tip: '标题过长会自动收缩',
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: '此项为必填项',
      //     },
      //   ],
      // },
    },
    {
      title: '评选阶段',
      dataIndex: 'levelname',
      width: 100,
      search:false,
    },
    {
      title: '指标阐述',
      dataIndex: 'desc',
      // width: 150,
      search:false,
      ellipsis: true,
    },
    {
      disable: true,
      title: '展示状态',
      dataIndex: 'status',
      width: 100,
      // search:false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      // valueType: 'select',
      // valueEnum: {
      //   "0": {
      //     text: '隐藏',
      //     status: 'Default',
      //   },
      //   "1": {
      //     text: '正常',
      //     status: 'Success',
      //     // disabled: true,
      //   },
      // },
      render: (text, record) => {
        console.log('status----------',text,record)
        return <Switch defaultChecked={text=="1"?true:false} size="small" onClick={(checked)=>onChangeStatus(record.sid, checked, 2)} />;
      },

    },
    {
      title: '计分方式',
      dataIndex: 'score',
      width: 80,
      search:false,
    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal_vote(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {onDelete(record.sid, 2)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white"}}/>
        </Popconfirm>
      
      ],
    },
  ];

  const tabClicked = key => {
    // for(var i=1; i<=3; i++)
    // {
    //   var span = document.getElementById("tab_pane_"+i);
    //   var bar = document.getElementById("tab_bar_"+i);
    //   if(span){
    //     if(i == key){
    //       setCurTabPane(i);
    //       span.className = "tab_pane_active";
    //       bar.className = "cust_tab_bar_active";
    //       if(i == 1 && actionRef_1.current){
    //         actionRef_1.current.reload();
    //         setCheckboxShow(false);
    //         setSelectPhones([]);
    //       } else if(i == 2 && actionRef_2.current){
    //         actionRef_2.current.reload();
    //       } else if(i == 3 && actionRef_3.current){
    //         actionRef_3.current.reload();
    //       }
    //     }
    //     else{
    //       span.className = "tab_pane";
    //       bar.className = "cust_tab_bar";
    //     }
    //   }
    // }

    // customTabinkbar();
  };

  // 修改状态
  const onChangeStatus = (sid, checked, type) =>{
    console.log(sid,checked)
    if(!sid)
      return;

    try {
      getApiData('A', "A_updateScorestandardStatus", {sid:sid, status:checked?"1":"0"}).then((res) => {
        console.log('A_updateScorestandardStatus-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
        }
        else{
          if(type == 1)
            actionRef.current?.reload();
          if(type == 2)
            actionRef_vote.current?.reload();
        }
      });
      } catch (error) {
        if(type == 1)
          actionRef.current?.reload();
        if(type == 2)
          actionRef_vote.current?.reload();
        return false;
      }
  }

  // 删除
  const onDelete = (sid, type) =>{
    console.log(sid)
    if(!sid)
      return;

    try {
      getApiData('A', "A_delScorestandard", {sid:sid}).then((res) => {
        console.log('A_delScorestandard-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          if(type == 1)
            actionRef.current?.reload();
          if(type == 2)
            actionRef_vote.current?.reload();
        }
      });
      } catch (error) {
        
        return false;
      }
  }

  return (
    <div>
    <Tabs style={{marginLeft:10}} defaultActiveKey={curTabPane} size='large' onTabClick={tabClicked} >
    <TabPane tab={<div><div id="tab_pane_1" className='tab_pane_active'><OrderedListOutlined />评分</div><div id="tab_bar_1" className='cust_tab_bar_active' ></div></div>} key="1" >
    <ProTable
      headerTitle="评分"
      rowKey="id"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        var tableData = [];
        try {
          params.judgetype = "1";
          await getApiData('A', 'A_scorestandardList', params).then((res) => {
            console.log('A_scorestandardList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={false}
      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={false}
      // pagination={{
      //   pageSize: 5,
      //   onChange: (page) => console.log(page),
      // }}
      // dateFormatter="string"
      // rowSelection={{
      //   // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
      //   // 注释该行则默认不显示下拉选项
      //   // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
      //   defaultSelectedRowKeys: [],
      // }}
      // tableAlertRender={({
      //   selectedRowKeys,
      //   selectedRows,
      //   onCleanSelected,
      // }) => {
      //   console.log(selectedRowKeys, selectedRows);
      //   return (
      //     <Space size={24}>
      //       <span>
      //         已选 {selectedRowKeys.length} 项
      //         <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
      //           取消选择
      //         </a>
      //       </span>
      //       {/* <span>{`容器数量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.containers,
      //         0,
      //       )} 个`}</span>
      //       <span>{`调用量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.callNumber,
      //         0,
      //       )} 次`}</span> */}
      //     </Space>
      //   );
      // }}
      // tableAlertOptionRender={() => {
      //   return (
      //     <Space size={16}>
      //       <a><EyeOutlined />设为正常</a>
      //       <a><EyeInvisibleOutlined />设为隐藏</a>
      //     </Space>
      //   );
      // }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
      ]}
    />
    </TabPane>
    <TabPane tab={<div><div id="tab_pane_2" className='tab_pane_active'><BarChartOutlined />投票</div><div id="tab_bar_1" className='cust_tab_bar_active' ></div></div>} key="2" >
    <ProTable
      headerTitle="投票"
      rowKey="cid"
      columns={columns_vote}
      actionRef={actionRef_vote}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        var tableData = [];
        try {
          params.judgetype = "2";
          await getApiData('A', 'A_scorestandardList', params).then((res) => {
            console.log('A_scorestandardList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={false}
      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={false}
      // pagination={{
      //   pageSize: 5,
      //   onChange: (page) => console.log(page),
      // }}
      // dateFormatter="string"
      // rowSelection={{
      //   // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
      //   // 注释该行则默认不显示下拉选项
      //   // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
      //   defaultSelectedRowKeys: [],
      // }}
      // tableAlertRender={({
      //   selectedRowKeys,
      //   selectedRows,
      //   onCleanSelected,
      // }) => {
      //   console.log(selectedRowKeys, selectedRows);
      //   return (
      //     <Space size={24}>
      //       <span>
      //         已选 {selectedRowKeys.length} 项
      //         <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
      //           取消选择
      //         </a>
      //       </span>
      //       {/* <span>{`容器数量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.containers,
      //         0,
      //       )} 个`}</span>
      //       <span>{`调用量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.callNumber,
      //         0,
      //       )} 次`}</span> */}
      //     </Space>
      //   );
      // }}
      // tableAlertOptionRender={() => {
      //   return (
      //     <Space size={16}>
      //       <a><EyeOutlined />设为正常</a>
      //       <a><EyeInvisibleOutlined />设为隐藏</a>
      //     </Space>
      //   );
      // }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal_vote(true)
          }}
          type="primary"
        >
          新建
        </Button>,
      ]}
    />
    </TabPane>
    </Tabs>  


    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      catList={catList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    <EditModel_Vote
      showModal={showModal_vote}
      closeModal={closeModal}
      rowInfo={rowInfo}
      catList={catList}
      refreshlist={()=>{actionRef_vote.current?.reload();}}
    />

    </div>
  );
};