import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout, Space,Modal,Select,Radio, AutoComplete,Image,Progress } from 'antd';
// import Icon,{EditOutlined  } from '@ant-design/icons';
// import './index.css';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import UploadFile from '../../components/UploadFile';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const screenHeight = document.body.clientHeight;
let followOptions={follow:[], follow_email:[], follow_phone:[]};

const App =(props)=>{
    const {showModal, closeModal, rowInfo, refreshlist} = props;
    console.log('1111111111111111111111111111111111111-', rowInfo)
    const [form] = Form.useForm();
    const [bEdit,setBEdit] = useState(true);
    const [loading,setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [attpercent, setAttpercent] = useState(0);
    const [attpercentShow,setAttpercentShow] = useState(false);

      if(rowInfo.cid == undefined){
        form.resetFields();
      } else {
        for (let key in rowInfo) {
          form.setFieldValue(key, rowInfo[key])
        }
      }

    useEffect(() => {
      if(showModal){
        if(rowInfo.cid == undefined){
          form.resetFields();
          setImageUrl("");
        } else {
          for (let key in rowInfo) {
            form.setFieldValue(key, rowInfo[key])
          }
          setImageUrl(rowInfo.logo);
        }
      }else{
        form.resetFields();
        setLoading(false);
        setImageUrl("");
        setAttpercent(0);
        setAttpercentShow(false);
      }
    }, [showModal]);

    // const mockVal = (str, repeat = 1) => ({
    //   value: str.repeat(repeat),
    // });
    
    // const onSearch = (searchText) => {
    //   setOptions(
    //     !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
    //   );
    // };
    // const onSelect = (data) => {
    //   console.log('onSelect', data);
    // };
    // const onChange = (data) => {
    //   setValue(data);
    // };

    const setOptions = async(key, value)=>{
      if(value){
        for(let item of followOptions[key]){
          if(item.value == value)
            return;
        }
        followOptions[key].push({value:value});
      }
    };

    const onFinish = async(value)=>{
      console.log(value);
      if(!imageUrl){
        message.warn("请上传公司logo");
        return;
      }
      value.logo = imageUrl;
      setLoading(true);
      setOptions("follow", value.follow);
      setOptions("follow_email", value.follow_email);
      setOptions("follow_phone", value.follow_phone);

      value.companyid = rowInfo.cid;

      // var p = {
      //   "companyid": rowInfo.cid,
      //   "status": value.status,
      //   "relation": value.relation,
      //   "follow": value.follow,
      //   "follow_email": value.follow_email,
      //   "follow_phone": value.follow_phone
      // };

      try {
        await getApiData('A', "A_editCompany", value).then((res) => {
          setLoading(false);
          console.log('A_editCompany-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            message.success("操作成功");
            closeModal();
            refreshlist();
          }
        });
        } catch (error) {
          message.error("网络异常");
          setLoading(false);
          return false;
        }
  }

  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width={600}
        bodyStyle={{maxHeight:screenHeight*0.8,overflowY:"scroll"}}
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button  className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:5,offset:2}}
            // style={{fontSize:12}}
            size="small"
            // initialValues={rowInfo}
        >
            {/* <Form.Item
                label="用户ID"
                name="cuid"
            >
              <Select
                  // placeholder="请选择选择栏目"
                  // onChange={onProdChange}
                  defaultValue={"1731"}
                  // allowClear
                  // style={{width:"50%"}}
                  disabled={true}
              >
                  <Option value="1731">13301083201</Option>
                  <Option value="1736">13482866355</Option>
                  <Option value="1782">1360846654@qq.com</Option>
                  <Option value="1780">龙小姐</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
                label="公司全称"
                name="cname"
                rules={[
                  {required: true, message: '请输入公司全称',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="公司简称"
                name="sname"
                rules={[
                  {required: true, message: '请输入公司简称',},
                ]}
            >
            <Input />
            </Form.Item>
            {/* <Form.Item
                label="公司英文名"
                name="ename"
            >
            <Input disabled />
            </Form.Item> */}
            <Form.Item
                label="公司logo"
                name="logo"
                rules={[
                  {required: true, message: '请上传公司logo',},
                ]}
            >
              {imageUrl ? 
                <Image
                  height={50}
                  src={imageUrl}
                /> : ""
              }
              <UploadFile 
                from = {"A"}
                upButton = {<Button style={{marginLeft:20}}>上传</Button>}
                uploadFileType = {".png, .jpg, .jpeg, .gif"}
                uploadFileSize = {5*1024*1024}
                setpercent = {setAttpercent}
                setPercentShow = {setAttpercentShow}
                setFileUrl = {setImageUrl}
              />
              <Progress percent={attpercent} strokeWidth={6} style={{top:5,display:attpercentShow?'block':'none'}}/>

            </Form.Item>
            <Form.Item
                label="联系人"
                name="contact"
                rules={[
                  {required: true, message: '请输入联系人',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  {required: true, message: '请输入手机号',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="职位"
                name="position"
                rules={[
                  {required: true, message: '请输入职位',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  {required: true, message: '请输入邮箱',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="地址"
                name="address"
                rules={[
                  {required: true, message: '请输入地址',},
                ]}
            >
            <Input />
            </Form.Item>
            {/* <Form.Item
                label="营业执照"
                name="credit"
            >
            <Input disabled />
            </Form.Item> */}
            <Form.Item
                label="审核状态"
                name="status"
            >
              <Radio.Group style={{width:"80%"}} disabled={bEdit?false:true}>
                <Space size={10}>
                <Radio value={"0"}>不通过</Radio>
                <Radio value={"1"}>通过</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                label="新/老客户"
                name="relation"
            >
              <Radio.Group style={{width:"80%"}} disabled={bEdit?false:true}>
                <Space size={10}>
                <Radio value={"1"}>新</Radio>
                <Radio value={"2"}>老</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                label="跟进人"
                name="follow"
            >
              <AutoComplete
                options={followOptions.follow}
                // onSelect={onSelect}
                // onSearch={onSearch}
                // placeholder="跟进人"
              />
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="follow_email"
            >
              <AutoComplete
                options={followOptions.follow_email}
                // onSelect={onSelect}
                // onSearch={onSearch}
                // placeholder="跟进人"
              />
            {/* <Input /> */}
            </Form.Item>
            <Form.Item
                label="手机号"
                name="follow_phone"
            >
              <AutoComplete
                options={followOptions.follow_phone}
                // onSelect={onSelect}
                // onSearch={onSearch}
                // placeholder="跟进人"
              />
            {/* <Input /> */}
            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
