import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Radio } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, levellist, refreshlist} = props;
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);

    useEffect(() => {
      if(rowInfo.jid == undefined){
        form.resetFields();
      } else {
        for(let key in rowInfo)
          form.setFieldValue(key, rowInfo[key])
      }

    }, [showModal]);

    // 提交
    const onFinish = async(value)=>{
      console.log(value);
      let fun = "";
      if(rowInfo.jid == undefined){
        fun = "A_createJudgegroup";
      } else {
        fun = "A_updateJudgegroup";
        value.jid = rowInfo.jid;
      }

      try {
        setLoading(true);
        await getApiData('A', fun, value).then((res) => {
          setLoading(false);
          console.log(fun+'-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            message.success("操作成功");
            closeModal();
            refreshlist();
          }
        });
        } catch (error) {
          message.error("网络异常");
          setLoading(false);
          return false;
        }
  }

  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.jid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button  className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>]}
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:4,offset:2}}
            style={{fontSize:12}}
            size="small"
            // initialValues={rowInfo}
        >
            <Form.Item
                label="阶段"
                name="level"
                rules={[
                  {required: true, message: '请选择评审类别',},
                ]}
            >
              <Select
                  // placeholder="请选择选择栏目"
                  // onChange={onProdChange}
                  defaultValue={""}
                  // allowClear
                  // style={{width:"50%"}}
                  options={levellist}
              />
            </Form.Item>
            <Form.Item
                label="评委组名"
                name="name"
                rules={[
                  {required: true, message: '请输入评委组名',},
                ]}
            >
            <Input />
            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
