/*
 * @Author: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @Date: 2024-08-05 19:05:23
 * @LastEditors: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @LastEditTime: 2024-09-05 15:45:39
 * @FilePath: \kpyunfsservicee:\git\topdigital\topdweb\admin\src\app.config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const AppConfig = {
    // 线上地址
    INTER_BASE:"//api.awards.topdigitalworld.com/index.php",
    INTER_IMPORT_CASE:"//api.awards.topdigitalworld.com/index.php?f=A_uploadCase&token=",//案例导入
    INTER_IMPORT_RATER:"//api.awards.topdigitalworld.com/index.php?f=A_uploadJudges&token=",//评委导入
    // 测试地址
    // INTER_BASE:"https://wxapp.kuaipantech.com/TopDigital/index.php",
    // INTER_IMPORT_CASE:"https://wxapp.kuaipantech.com/TopDigital/index.php?f=A_uploadCase&token=",//案例导入
    // INTER_IMPORT_RATER:"https://wxapp.kuaipantech.com/TopDigital/index.php?f=A_uploadJudges&token=",//评委导入

}


export default AppConfig;