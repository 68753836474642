import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Cascader,Switch } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;


const groupOptions = [
  {
      value: '1',
      label: '初评',
      children: [
      {
          value: '11',
          label: '初评1组',
      },
      {
          value: '12',
          label: '初评2组',
      }
      ],
  },
  {
      value: '2',
      label: '复评',
      children: [
      {
          value: '21',
          label: '复评1组',
      },
      {
          value: '22',
          label: '复评2组',
      }
      ],
  },
  {
      value: '3',
      label: '终评',
      children: [
      {
          value: '31',
          label: '终评1组',
      },
      ],
  },
];



const App =(props)=>{
    const {showModal, closeModal, rowInfo, catList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    var catoption = [
      {value:"0", label:"初评"},
      {value:"1", label:"复评"},
      {value:"2", label:"终评"},
    ];
    var judgetype = [
      {value:"0", label:"评分+评论"},
      {value:"1", label:"投票+评论"},
    ];
    for(let v of catList){
      catoption.push({value:v.cid, label:(v.sep?" ":"")+v.sep+v.name});
    }
    console.log('catoption --------------------------- ',catoption)

    if(rowInfo.cid == undefined){
      form.resetFields();
      form.setFieldValue("pid", "0")
    } else {
      form.setFieldValue("pid", rowInfo.pid)
      form.setFieldValue("name", rowInfo.name)
      form.setFieldValue("key", rowInfo.key)
    }

    useEffect(() => {
      // var localLoginfo = localStorageGet('loginfo');
      // // console.log('localLoginfo:----------- ', localLoginfo);
      // if(localLoginfo){
      //   var loginfo_ = JSON.parse(decrypt(localLoginfo));
      //   // console.log('loginfo_--------------', loginfo_)
      //   form.setFieldValue('userName', loginfo_.userName);
      //   form.setFieldValue('password', loginfo_.password);
      //   setChecked(true);
      // }
      // else{
      //   setTimeout(()=>{
      //     form.setFieldValue('userName', "");
      //     form.setFieldValue('password', "");
      //     setChecked(false);

      //   }, 100)
      // }
    }, []);
    

    const onFinish = async(value)=>{
        console.log(value);
        let fun = "";
        if(rowInfo.cid == undefined){
          fun = "A_createCatelog";
        } else {
          fun = "A_updateCatelog";
          value.cid = rowInfo.cid;
        }

        try {
          await getApiData('A', fun, value).then((res) => {
            console.log(fun+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:4,offset:2}}
            size="small"
            // initialValues={rowInfo}
        >
            <Form.Item
                label="阶段"
                name="pid"
            >
              <Input placeholder="阶段" defaultValue={""} />
            </Form.Item>
            <Form.Item
                label="评选方式"
                name="type"
            >
              <Select
                  // placeholder="请选择选择栏目"
                  // onChange={onProdChange}
                  defaultValue={"0"}
                  // allowClear
                  // style={{width:"50%"}}
                  options={judgetype}
              />
            </Form.Item>
            <Form.Item
                label="状态"
                name="bank"
            >
              <Switch defaultChecked={true} size="large"  />

            </Form.Item>
        </Form>
        </Modal>
  )
}
export default App
