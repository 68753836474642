// import { ProColumns } from '@ant-design/pro-components';
import React, { useState, useRef,useEffect } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import {  Input, } from 'antd';
import {
  EditableProTable,
} from '@ant-design/pro-components';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import "./tmp.css"

const { TextArea } = Input;

const waitTime = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};



export default (props) => {
  const {dataSource, setDataSource} = props;
  const [editableKeys, setEditableRowKeys] = useState([]);
  // const [dataSource, setDataSource] = useState([]);
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  useEffect(() => {

  }, [dataSource]);

  const DragHandle = SortableHandle(() => (
    <SwapOutlined 
      rotate={90}
      style={{
        cursor: 'grab',
        color: '#999',
      }}
    />
  ));
  
  const columns = [
    {
      title: '排序',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      editable: false,
      render: () => <DragHandle />,
    },
    {
      title: '标题',
      dataIndex: 'title',
      className: 'drag-visible',
      // tooltip: '只读，使用form.getFieldValue获取不到值',
      formItemProps: (form, { rowIndex }) => {
        return {
          rules:
            rowIndex > 1 ? [{ required: true, message: '请输入标题' }] : [],
        };
      },
      // 前三行不允许编辑
      editable: (text, record, index) => {
        return !(record.name=='banner' || record.name=='name' || record.name=='category' || record.name=='showcomp' || record.name=='showcomplogo') ;
      },
      width: '15%',
    },
    {
      title: '类型',
      key: 'type',
      dataIndex: 'type',
      width: '20%',
      editable: (text, record, index) => {
        return !(record.name=='banner' || record.name=='name' || record.name=='category' || record.name=='showcomp' || record.name=='showcomplogo') ;
      },
      formItemProps: (form, { rowIndex }) => {
        return {
          rules:
            rowIndex > 1 ? [{ required: true, message: '请选择类型' }] : [],
        };
      },
      valueType: 'select',
      valueEnum: {
        "text": {
          text: '文本',
          // status: 'Default',
        },
        "upload": {
          text: '图片上传',
          // status: 'Success',
        },
        "select_category": {
          text: '参赛组别选择',
          // status: 'Success',
          disabled: true,
        },
        "range": {
          text: '时间范围',
          // status: 'Success',
        },
        "select_custom": {
          text: '下拉框',
          // status: 'Success',
        },
        "editor": {
          text: '富文本',
          // status: 'Success',
        },
      },
    },
    {
      title: '选项数据',
      dataIndex: 'selectdata',
      tooltip: '每行一项选择数据',
      // width: '15%',
      editable: (text, record, index) => {
        return !(record.name=='banner' || record.name=='name' || record.name=='category' || record.name=='showcomp' || record.name=='showcomplogo') ;
      },
      renderFormItem: () => <TextArea rows={2} placeholder="每行一项" />,
      // render: (_, row) => row?.labels?.map((item) => <Tag key={item.key}>{item.label}</Tag>),
    },
    {
      title: '描述',
      dataIndex: 'desc',
      // fieldProps: (form, { rowKey, rowIndex }) => {
      //   if (form.getFieldValue([rowKey || '', 'title']) === '不好玩') {
      //     return {
      //       disabled: true,
      //     };
      //   }
      //   if (rowIndex > 9) {
      //     return {
      //       disabled: true,
      //     };
      //   }
      //   return {};
      // },
    },
    {
      title: '操作',
      valueType: 'option',
      width: 120,
      render: (text, record, _, action) => {return [
        <a
          key="editable"
          onClick={() => {
            action?.startEditable?.(record.id);
          }}
        >
          编辑
        </a>,
        <a
          key="delete"
          onClick={() => {
            setDataSource(dataSource.filter((item) => item.id !== record.id));
          }}
        >
          <span style={{display: record.name=='banner'||record.name=='name'||record.name=='category' || record.name=='showcomp' || record.name=='showcomplogo' ? "none" : ""}}>删除</span>
        </a>,
      ]},
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log('oldIndex - ', oldIndex)
    console.log('newIndex - ', newIndex)
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      console.log('Sorted items: ', newData);
      setDataSource(newData);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
      <EditableProTable
        rowKey="id"
        // headerTitle="可编辑表格"
        // maxLength={5}
        // scroll={{
        //   x: 960,
        // }}
        style={{marginTop:15,}}
        recordCreatorProps={
          {
            creatorButtonText: '追加',
            position: "bottom",
            record: () => ({ id: (Math.random() * 1000000).toFixed(0), name: "no_"+(Math.random() * 1000000).toFixed(0)}),
          }
        }
        loading={false}
        toolBarRender={() => []}
        columns={columns}
        request={async () => ({
          data: dataSource,
          // total: 3,
          success: true,
        })}
        value={dataSource}
        onChange={(setDataSource)}
        editable={{
          type: 'multiple',
          editableKeys,
          onSave: async (rowKey, data, row) => {
            console.log(rowKey, data, row);
            await waitTime(100);
          },
          onChange: setEditableRowKeys,
          actionRender: (row, config, dom) => [dom.save, dom.cancel],
        }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
  );
};