import React, {useEffect,useImperativeHandle,forwardRef} from 'react'
 
const UEditor = (props,ref) => {
    let editor = null
    console.log('show ----------- ', props.show)
    useEffect(() => {
        if(props.show)
            setConfig(props.initData,props.config,props.setContent)
        // else{
        //     console.log('55555555555555555555555555')
        //     editor.destroy();
        //     editor.removeListener(); //不要打开，否则返回有问题报错
        // }
    },[props.show])
    // 初始化编辑器
    const setConfig = (initData,config,setContent) => {
        try{
            window.UE.delEditor(props.id);
            editor = window.UE && window.UE.getEditor(props.id, {
                // enableAutoSave: false,
                // autoHeightEnabled: false,
                autoFloatEnabled: false,
                initialFrameHeight: (config&&config.initialFrameHeight) || 450,
                initialFrameWidth: (config&&config.initialFrameWidth) || '100%',
                zIndex: 1000,
            })
            // console.log('editor -----111111111111----------', editor)
            // editor.ready(() => {
            //     if(initData != null){
            //         editor.setContent(initData)  //设置默认值/表单回显
            //     }
            // })
            console.log('editor -----444444444444422222----------', editor)
            editor.addListener("ready",function(){
                console.log("ready -----------------")
                editor.setContent(initData)
            });
            editor.addListener("blur",function(){
                console.log('editor.getContent()------', editor.getContent())
                setContent(editor.getContent())
            });

        } catch(e) {
            console.log('UEdit error !!!!!!!!!!!!!!!!!!', e)
        }
    }
    // useImperativeHandle(ref,()=>({
    //     getUEContent: ()=> {
    //         console.log('editor -----222222222222----------', editor)
    //         return editor.getContent() //获取编辑器内容
    //     }
    // }))
    return (
      <script id={props.id} type="text/plain" />
    )
}
 
export default forwardRef(UEditor) 