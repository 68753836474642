import React , { useState, useEffect, useRef, useContext }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Radio,Popconfirm,Table } from 'antd';
import Icon,{EditOutlined ,PlusOutlined } from '@ant-design/icons';
// import './index.css';
import Tmp from './tmp.jsx'
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const screenHeight = document.body.clientHeight;

const initData = [
  {
    "id":"11",
    "key":"1",
    "name":"banner",
    "title":"特色头图",
    "type":"upload",
    "desc":"建议上传图片尺寸为：1080px * 500px",
    "selectdata":"",
    "uneditable": true,
  },
  {
    "id":"12",
    "key":"2",
    "name":"name",
    "title":"作品名称",
    "type":"text",
    "desc":"限24字以内，单个英文、符号、空格算一个字符",
    "selectdata":"24",
    "uneditable": true,
  },
  {
    "id":"13",
    "key":"3",
    "name":"category",
    "title":"参赛组别",
    "type":"select_category",
    "desc":"",
    "selectdata":"",
    "uneditable": true,
  },
  {
    "id":"14",
    "key":"4",
    "name":"showcomp",
    "title":"参赛单位",
    "type":"text",
    "desc":"限15字以内，本栏所填信息将用于获奖后的榜单公布及奖杯制作",
    "selectdata":"15",
    "uneditable": true,
  },
  {
    "id":"15",
    "key":"5",
    "name":"showcomplogo",
    "title":"参赛单位logo",
    "type":"upload",
    "desc":"",
    "selectdata":"",
    "uneditable": true,
  },
]

const initData_2 = [
  {
    "id":"21",
    "key":"1",
    "name":"goal",
    "title":"背景与目标",
    "type":"editor",
    "desc":"",
    "selectdata":"",
  },
]


const App =(props)=>{
    const {showModal, closeModal, rowInfo, catelog0List, tempIdList, refreshList} = props;
    console.log('rowInfo-----------', rowInfo)
    console.log('catelog0List-----------', catelog0List)
    console.log('idlist tempIdList-----------', tempIdList)
    // const [tempTitle, setTempTitle] = useState([]);
    const [curTemplate, setCurTemplate] = useState("");
    const [dataSource, setDataSource] = useState(initData);
    const [dataSource_2, setDataSource_2] = useState(initData_2);
    const [tmpOptions, setTmpOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(showModal){
        if(rowInfo.tid){
          setCurTemplate(rowInfo.title);
          var base = rowInfo.template.base;
          var offer = rowInfo.template.offer;
          for(let i in base){
            if(base[i].selectdata){
              try{
                base[i].selectdata = base[i].selectdata.join("\n");
              } catch(e) {
                
              }
            }
          }
          for(let i in offer){
            if(offer[i].selectdata){
              try{
                offer[i].selectdata = offer[i].selectdata.join("\n");
              } catch(e) {
                
              }

            }
          }
  
          setDataSource(base);
          setDataSource_2(offer);
        } else {
          setCurTemplate("");
          setDataSource(initData);
          setDataSource_2(initData_2);
          console.log(tmpOptions)
          console.log(tempIdList)
          var list = [];
          for(let i in catelog0List){
            let item = catelog0List[i];
            console.log(item)
            if(tempIdList.includes(catelog0List[i].value))
              item.disabled = true;
            else
              item.disabled = false;
            list.push(item);
          }
          setTmpOptions(list);
          // try {
          //   getApiData('A', 'A_catelogList', {pid:"0"}).then((res) => {
          //     console.log('A_catelogList-res-----------------', res);
          //     var flag = checkLogin('A', res.data)
          //     if (flag) {
          //       var options=[{value:"", label:"--请选择--"}];
          //       for(let v of res.data.d.list){
          //         options.push({value:v.cid, label:v.name})
          //       }
          //       setTempTitle(options);
          //     }
          //   });
          // } catch (error) {
            
          //   // return false;
          // }
        }
      } else {
        setCurTemplate("");
        setDataSource(initData);
        setDataSource_2(initData_2);
        setTmpOptions([]);
      }
    }, [showModal]);

    
//   var tempTitle = [
//   {
//     "value": "17315",
//     "label": "E-年度机构奖【Company】",
//   },
//   {
//     "value": "17314",
//     "label": "D-年度技术产品奖【Product】",
//   },
//   {
//     "value": "17313",
//     "label": "C-年度专项奖【Special】",
//   },
//   {
//     "value": "17312",
//     "label": "B-创意作品类【Work】",
//   },
//   {
//     "value": "17311",
//     "label": "A-营销案例类【Case】",
//   },
// ];


    const selectCat = (value)=>{
      setCurTemplate(value);
    }

    const onFinish = async()=>{
        if(!curTemplate){
          message.warn("请选选择模板名称");
          return false;
        }
        console.log('dataSource ------- ', dataSource);
        console.log('dataSource_2 ------- ', dataSource_2);
        var base = dataSource;
        var offer = dataSource_2;
        for(let i in base){
          if(base[i].selectdata && typeof(base[i].selectdata)=='string')
            base[i].selectdata = base[i].selectdata.split("\n");
        }
        for(let i in offer){
          if(offer[i].selectdata && typeof(offer[i].selectdata)=='string')
            offer[i].selectdata = offer[i].selectdata.split("\n");
        }
				var p = {
          catid:curTemplate,
          template:{
            base:base,
            offer:offer,
          }
        }
        var f = "A_createTemplate";
        if(rowInfo.tid){
          f = "A_updateTemplate"
          p.tid = rowInfo.tid;
        }

        try {
          setLoading(true);
          await getApiData('A', f, p).then((res) => {
            setLoading(false);
            console.log(f+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshList();
            }
          });
        } catch (error) {
          message.error("网络异常");
          setLoading(false);
          return false;
        }
    }

  return (
    <Modal 
      key="basicinfo"
      title={rowInfo.tid ? '编辑' :'添加'}
      open={showModal}
      width={"85%"}
      bodyStyle={{height:screenHeight*0.8,overflowY:"scroll"}}
      centered
      destroyOnClose={true}
      maskClosable={false}
      footer={[<Button  className="m-btn-white" onClick={closeModal}>
      取消
      </Button>,
      <Button type="primary" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={onFinish}>
      确认
      </Button>]}
      onCancel={closeModal}
    >
      <Space direction="horizontal" style={{marginBottom:30}}>
        <div style={{fontSize:14,marginRight:10,}}>模板名称</div>
        {/* {rowInfo.tid ? (<span>{rowInfo.title}</span>) : (<Select value={curTemplate} onChange={selectCat} options={catelog0List} />)} */}
        <Select value={curTemplate} onChange={selectCat} options={tmpOptions} disabled={rowInfo.tid?true:false} />
      </Space>
      <div style={{fontSize:14,marginRight:10}}>基本信息</div>
      <Tmp
        dataSource={dataSource}
        setDataSource={setDataSource}
      />

      <div style={{fontSize:14,marginRight:10,marginTop:30}}>作品描述</div>
      <Tmp
        dataSource={dataSource_2}
        setDataSource={setDataSource_2}
      />

    </Modal>
  )
}
export default App
