import React, { useState,useEffect,useRef }  from 'react';
import { Button, Popconfirm, message } from 'antd';
import { PlusOutlined,EditOutlined,DeleteOutlined,FolderViewOutlined,SwapOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import EditModel from './components/edit';
import PreviewModel from './components/preview';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [catelog0List, setCatelog0List] = useState([]);
  const [tempIdList, setTempIdList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [canSort, setCanSort] = useState(false);
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const DragHandle = SortableHandle(() => (
    <SwapOutlined 
      rotate={90}
      style={{
        cursor: 'grab',
        color: '#999',
      }}
    />
  ));
  
  useEffect(() => {
    try {
      getApiData('A', 'A_catelogList', {pid:"0", status:"1", usearea:"template"}).then((res) => {
        console.log('A_catelogList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          var options=[{value:"", label:"--请选择--"}];
          for(let v of res.data.d.list){
            options.push({value:v.cid, label:v.name})
          }
          setCatelog0List(options);
        }
      });
    } catch (error) {
      
      // return false;
    }


  }, []);

  // 删除模板
  const deleteTemp = (tid) =>{
    try {
      getApiData('A', 'A_delTemplate', {tid:tid}).then((res) => {
        console.log('A_delTemplate-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }
  
  const closeModal = () =>{
    setShowModal(false);
    setShowPreviewModal(false);
  }

  const columns = [
    {
      title: '排序',
      dataIndex: 'sortindex',
      width: 50,
      className: 'drag-visible',
      search: false,
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      // valueType: 'indexBorder',
      // width: 100,
      search:false
    },
    {
      title: '模板名称',
      dataIndex: 'title',
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      valueType: 'dateTime',
      width: 200,
      search:false
    },
    {
      title: '操作',
      valueType: 'option',
      width: 60,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="preview"
          onClick={async() => {
            // console.log('record ------------- ', record)
            // console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowPreviewModal(true);
          }}
        >
          <FolderViewOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <a
          key="editable"
          onClick={async() => {
            // console.log('record ------------- ', record)
            // console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteTemp(record.tid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </Popconfirm>
      // <a href={record.url} target="_blank" rel="noopener noreferrer" key="view">
        //   查看
        // </a>,
        // <TableDropdown
        //   key="actionGroup"
        //   onSelect={() => action?.reload()}
        //   menus={[
        //     { key: 'copy', name: '复制' },
        //     { key: 'delete', name: '删除' },
        //   ]}
        // />,
      ],
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log('oldIndex - ', oldIndex)
    console.log('newIndex - ', newIndex)
    if (oldIndex !== newIndex) {
      try {
        getApiData('A', 'A_sortTemplate', {from:dataSource[oldIndex].tid, to:dataSource[newIndex].tid}).then((res) => {
          console.log('A_sortTemplate-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            console.log('-------------ok-------')
            actionRef.current?.reload();
            // const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
            //   (el) => !!el,
            // );
            // console.log('Sorted items: ', newData);
            // setDataSource(newData);
          }
        });
      } catch (error) {
        // return false;
      }
  
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div>
          
    <ProTable
      headerTitle="模板管理"
      rowKey="id"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log(sort, filter);
        // await waitTime(2000);
        var tableData = [];
        var idlist = [];
        try {
          await getApiData('A', 'A_templateList', params).then((res) => {
            console.log('A_templateList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setDataSource(res.data.d.list);
              for(let item of tableData)
                idlist.push(item.catid);

                console.log('idlist --------- ', idlist)
              setTempIdList(idlist);
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={{
        labelWidth: 'auto',
      }}
      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={false}
      // pagination={{
      //   pageSize: 5,
      //   onChange: (page) => console.log(page),
      // }}
      // dateFormatter="string"
      // rowSelection={{
      //   // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
      //   // 注释该行则默认不显示下拉选项
      //   // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
      //   defaultSelectedRowKeys: [],
      // }}
      // tableAlertRender={({
      //   selectedRowKeys,
      //   selectedRows,
      //   onCleanSelected,
      // }) => {
      //   console.log(selectedRowKeys, selectedRows);
      //   return (
      //     <Space size={24}>
      //       <span>
      //         已选 {selectedRowKeys.length} 项
      //         <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
      //           取消选择
      //         </a>
      //       </span>
      //       {/* <span>{`容器数量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.containers,
      //         0,
      //       )} 个`}</span>
      //       <span>{`调用量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.callNumber,
      //         0,
      //       )} 次`}</span> */}
      //     </Space>
      //   );
      // }}
      // tableAlertOptionRender={() => {
      //   // return (
      //   //   <Space size={16}>
      //   //     <a><EyeOutlined />设为正常</a>
      //   //     <a><EyeInvisibleOutlined />设为隐藏</a>
      //   //   </Space>
      //   // );
      // }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
      ]}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}

    />


    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      catelog0List={catelog0List}
      tempIdList={tempIdList}
      refreshList={()=>{actionRef.current?.reload();}}
    />

    <PreviewModel
      showModal={showPreviewModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
    />

    </div>
  );
};