import React, { useState, useRef,useEffect } from 'react';
import { SmileOutlined,DeleteOutlined } from '@ant-design/icons';
import {
  // FooterToolbar,
  PageContainer,
  ProForm,
  ProFormDateRangePicker,
  ProFormDigit,
  // ProFormRadio,
  // ProFormSelect,
  ProFormText,
  // ProFormTextArea,
  // ProFormUploadButton,
  // ProLayout,
  // EditableProTable,
  ProFormDateTimePicker,
  ProFormMoney
} from '@ant-design/pro-components';
import { Space, message } from 'antd';
import UpLogo from './components/uplogo';
import UpPDF from './components/uppdf';
import EditTable from './components/editTable';
// import UEditor from '../../components/UEditor';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

const waitTime = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    width: '30%',
    formItemProps: {
      rules: [
        {
          required: true,
          whitespace: true,
          message: '名称不能为空',
        },
      ],
    },
  },
  {
    title: '金额',
    dataIndex: 'fee',
    width: '50%',
  },
  {
    title: '',
    valueType: 'option',
  },
];

const columns_notetype = [
  {
    title: '名称',
    dataIndex: 'name',
    width: '80%',
    formItemProps: {
      rules: [
        {
          required: true,
          whitespace: true,
          message: '名称不能为空',
        },
      ],
    },
  },
  // {
  //   title: '金额',
  //   dataIndex: 'fee',
  //   width: '50%',
  // },
  {
    title: '',
    valueType: 'option',
  },
];

// const defaultData_print = [
//   {
//     id: 1,
//     name: '证书',
//     fee: '500.00',
//   },
//   {
//     id: 2,
//     name: '证书与奖杯',
//     fee: '3000.00',
//   },
// ];

export default () => {
  const ueRef = useRef(null)
  const formRef = useRef();
  const [formData, setFormData] = useState({});

  // const [editableKeys, setEditableRowKeys] = useState(() =>
  //   defaultData.map((item) => item.id),
  // );

    //编辑器的宽度
  const [config] = useState({
    initialFrameWidth: '100%',
    initialFrameHeight: 400
  })
  
  //富文本失焦就触发setContent函数设置表单的content内容
  const setContent = (e)=>{
      // form.setFieldsValue({
      //     content: ueRef.current.getUEContent()
      // })
      console.log('333333333333333333333333333 - ', e)
      formRef?.current?.setFieldValue("feerule", e);
  }
  
  useEffect(() => {
    // if(formData.reward){
    //   setEditableRowKeys_reward(() =>
    //     formData.reward.map((item) => item.id)
    //   );
    // }
    // if(formData.print){
    //   setEditableRowKeys_print(() =>
    //     formData.print.map((item) => item.id)
    //   );
    // }

  }, [formData]);

  
  const onFinish = async(value)=>{
    console.log(value);

    try {
      await getApiData('A', 'A_updateBaseConfig', {config:value}).then((res) => {
        console.log('A_updateBaseConfig-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
        }
      });
      } catch (error) {
        
        return false;
      }
  }

  // type LayoutType = Parameters<typeof ProForm>[0]['layout'];
  const LAYOUT_TYPE_HORIZONTAL = 'horizontal';

  const [formLayoutType, setFormLayoutType] = useState(
    LAYOUT_TYPE_HORIZONTAL,
  );

  const formItemLayout =
    formLayoutType === LAYOUT_TYPE_HORIZONTAL
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;
  

  return (
    console.log('formData--------', formData),
    <PageContainer title="基础配置">
      {/* <Card> */}
        <ProForm
          formRef={formRef}
          layout={formLayoutType}
          labelCol= {{ span: 6, offset:1 }}
          // wrapperCol= {{ span: 14 }}
          labelAlign="left"
          style={{paddingRight:10}}
          // initialValues={formData}
          submitter={{
            render: (props, doms) => {
              return formLayoutType === LAYOUT_TYPE_HORIZONTAL ? (
                <div style={{textAlign:"center",marginTop:30,marginBottom:20}}>
                <Space size={30} >{doms}</Space>
                </div>
              ) : (
                doms
              );
            },
          }}
          onFinish={onFinish}
          request={async () => {
            await waitTime(100);
            var config = {};
            // var keys_reward = [];
            // var keys_print = [];
            try {
                await getApiData('A', 'A_getBaseConfig', {tag:""}).then((res) => {
                  console.log('A_getBaseConfig-res-----------------', res);
                  var flag = checkLogin('A', res.data)
                  if (flag) {
                    if(res.data.d.config)
                    {
                      config = res.data.d.config;
                      for(let k in config.reward){
                        config.reward[k].id = parseInt(k)+1;
                        // keys_reward.push(parseInt(k)+1);
                      }
                      for(let k in config.print){
                        config.print[k].id = parseInt(k)+1;
                        // keys_print.push(parseInt(k)+1);
                      }
                      for(let k in config.notetype){
                        config.notetype[k].id = parseInt(k)+1;
                      }
                    }
                    // formRef?.current?.setFieldsValue(res.data.d);
                  }
                });
            } catch (error) {
              
              // return false;
            }
            setFormData(config);
            return config;
          }}
        >

          <ProFormDigit name="freecasecnt" label="默认案例免费提交案例数量" />
          <ProFormMoney name="casefee" label="超出作品报名费用" 
            fieldProps={{
              moneySymbol: false,
            }} />
          <ProFormDateTimePicker name="freecaseendtime" label="案例提交截止时间" />
          <ProFormDateTimePicker name="resultshowtime" label="评审结果公布时间" />
          <ProFormDateRangePicker name="runtime" label="案例执行时间" />
          <ProFormText name="emailnotice" label="邮件通知底部连接" />

          <UpLogo
            label = {"参赛端LOGO"}
            name = {"clientlogo"}
            formRef = {formRef}
            initdata = {formData.clientlogo}
          />
          <UpLogo
            label = {"评委端LOGO"}
            name = {"judgelogo"}
            formRef = {formRef}
            initdata = {formData.judgelogo}
          />
          <UpLogo
            label = {"参赛/评委登录banner"}
            name = {"loginbanner"}
            formRef = {formRef}
            initdata = {formData.loginbanner}
          />

          <EditTable
            label = {"获奖配置"}
            name = {"reward"}
            columns = {columns}
            initdata = {formData.reward}
          />

          <EditTable
            label = {"加印单价"}
            name = {"print"}
            columns = {columns}
            initdata = {formData.print}
          />

          <EditTable
            label = {"开票内容"}
            name = {"notetype"}
            columns = {columns_notetype}
            initdata = {formData.notetype}
          />

          <UpPDF
            label = {"创新营销奖参赛指南"}
            name = {"cansaizhinan"}
            formRef = {formRef}
            initdata = {formData.cansaizhinan}
          />

          <UpPDF
            label = {"创新营销奖参赛报名+作品描述模板"}
            name = {"baomingmoban"}
            formRef = {formRef}
            initdata = {formData.baomingmoban}
          />

          <UpPDF
            label = {"创新营销奖参赛作品报送指南"}
            name = {"baosongzhinan"}
            formRef = {formRef}
            initdata = {formData.baosongzhinan}
          />

          <UpPDF
            label = {"用户作品提交协议书"}
            name = {"caseagreement"}
            formRef = {formRef}
            initdata = {formData.caseagreement}
          />

        {/* <ProForm.Item
          label="费用规则"
          name="feerule"
        >
          <UEditor id={"feerule"} ref={ueRef} config={config} initData={formData.feerule} setContent={(e)=>setContent(e)}></UEditor>
        </ProForm.Item> */}
      </ProForm>
      {/* </Card> */}
    </PageContainer>
  );
};