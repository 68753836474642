import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, catList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    var catoption = [{value:"0", label:"无"}];
    for(let v of catList){
      let one = {value:v.cid, label:(v.sep?" ":"")+v.sep+v.name};
      if(rowInfo.cid == v.cid)
        one.disabled = true;
      catoption.push(one);
    }
    console.log('catoption --------------------------- ',catoption)

    if(rowInfo.cid == undefined){
      form.resetFields();
      form.setFieldValue("pid", "0")
    } else {
      form.setFieldValue("pid", rowInfo.pid)
      form.setFieldValue("name", rowInfo.name)
      form.setFieldValue("key", rowInfo.key)
    }

    useEffect(() => {
      // var localLoginfo = localStorageGet('loginfo');
      // // console.log('localLoginfo:----------- ', localLoginfo);
      // if(localLoginfo){
      //   var loginfo_ = JSON.parse(decrypt(localLoginfo));
      //   // console.log('loginfo_--------------', loginfo_)
      //   form.setFieldValue('userName', loginfo_.userName);
      //   form.setFieldValue('password', loginfo_.password);
      //   setChecked(true);
      // }
      // else{
      //   setTimeout(()=>{
      //     form.setFieldValue('userName', "");
      //     form.setFieldValue('password', "");
      //     setChecked(false);

      //   }, 100)
      // }
    }, []);
    

    const onFinish = async(value)=>{
        console.log(value);
        let fun = "";
        if(rowInfo.cid == undefined){
          fun = "A_createCatelog";
        } else {
          fun = "A_updateCatelog";
          value.cid = rowInfo.cid;
        }

        try {
          setLoading(true);
          await getApiData('A', fun, value).then((res) => {
            console.log(fun+'-res-----------------', res);
            setLoading(false);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:3,offset:2}}
            size="small"
            // initialValues={rowInfo}
        >
            <Form.Item
                label="父ID"
                name="pid"
            >
              <Select
                  // placeholder="请选择选择栏目"
                  // onChange={onProdChange}
                  // defaultValue={"0"}
                  // allowClear
                  // style={{width:"50%"}}
                  options={catoption}
              />
            </Form.Item>
            <Form.Item
                label="名称"
                name="name"
            >
            <Input placeholder="名称" />
            </Form.Item>
            <Form.Item
                label="编号"
                name="key"
                rules={[
                  {required: true, message: '请输入编号',},
                ]}
            >
            <Input placeholder="编号" />
            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
