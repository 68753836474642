import Icon, {LineChartOutlined,ProjectOutlined,AccountBookOutlined,BankOutlined,
    ApartmentOutlined,CheckSquareOutlined,TeamOutlined,
    TrophyOutlined,SettingOutlined,ProfileOutlined,UnlockOutlined } from '@ant-design/icons';
import Dashboard from '../page/dashboard/index.jsx';
import Category from '../page/set/category/index.jsx';
import Judgeflow from '../page/set/judgeflow/index.jsx';
import Judgequota from '../page/set/judgequota/index.jsx';
import Template from '../page/set/template/index.jsx';
import Company from '../page/company/index.jsx';
import Invoice from '../page/invoice/index.jsx';
import Case from '../page/case/index.jsx';
import Raterteam from '../page/raterteam/index.jsx';
import Rater from '../page/rater/index.jsx';
import Member from '../page/member/index.jsx';
import Overprint from '../page/overprint/index.jsx';
import Profile from '../page/general/profile/index.jsx';
import Config from '../page/general/config/index.jsx';
import Auth from '../page/auth/auth/index.jsx';
import Account from '../page/auth/account/index.jsx';


/**
 *   isPublic:页面是否为公开页面；
 *   hideInMenu:是否在左侧菜单栏隐藏；
 *   当前版本不使用这个变量 add by jerry
 */
const menuList=[
{
    title:'控制台',
    path:'/admin/dashboard',
    component:<Dashboard/> ,
    icon:<LineChartOutlined />,
    isPublic:true ,   //公开页面
},
{
    title:'常规管理11',
    path:'/admin/general',
    component:<Category/> ,
    icon:<SettingOutlined />,
    show: true,
    children : [
        {
            title:'个人配置',
            path:'/admin/general/profile',
            component:<Profile/> ,
            // icon:<OrderedListOutlined />,
            show: true,
        },
        {
            title:'基础配置',
            path:'/admin/general/config',
            component:<Config/> ,
            // icon:<GroupOutlined />,
            show: true,
        },
    ]
},
{
    title:'权限管理',
    path:'/admin/auth',
    // component:<Category/> ,
    icon:<UnlockOutlined />,
    show: true,
    children : [
        {
            title:'账号权限',
            path:'/admin/auth/auth',
            component:<Auth/> ,
            // icon:<OrderedListOutlined />,
            show: true,
        },
        {
            title:'账号管理',
            path:'/admin/auth/account',
            component:<Account/> ,
            // icon:<GroupOutlined />,
            show: true,
        },
    ]
},
{
    title:'参赛及评审设置',
    path:'/admin/set',
    component:<Category/> ,
    icon:<ProfileOutlined />,
    show: true,
    children : [
        {
            title:'分类管理',
            path:'/admin/set/category',
            component:<Category/> ,
            // icon:<OrderedListOutlined />,
            show: true,
        },
        {
            title:'模板管理',
            path:'/admin/set/template',
            component:<Template/> ,
            // icon:<GroupOutlined />,
            show: true,
        },
        {
            title:'评审流程设置',
            path:'/admin/set/judgeflow',
            component:<Judgeflow/> ,
            // icon:<GroupOutlined />,
            show: true,
        },
        {
            title:'评审指标设置',
            path:'/admin/set/judgequota',
            component:<Judgequota/> ,
            // icon:<GroupOutlined />,
            show: true,
        },
    ]
},
{
    title:'公司管理',
    path:'/admin/company',
    component:<Company/> ,
    icon:<BankOutlined />,
    show: true,
},
{
    title:'用户管理',
    path:'/admin/member',
    component:<Member/> ,
    icon:<TeamOutlined />,
    show: true,
},
{
    title:'案例管理',
    path:'/admin/case',
    component:<Case/> ,
    icon:<ProjectOutlined />,
    show: true,
},
{
    title:'开票管理',
    path:'/admin/invoice',
    component:<Invoice/> ,
    icon:<AccountBookOutlined />,
    show: true,
},
{
    title:'评委组管理',
    path:'/admin/raterteam',
    component:<Raterteam/> ,
    icon:<ApartmentOutlined />,
    show: true,
},
{
    title:'评委管理',
    path:'/admin/rater',
    component:<Rater/> ,
    icon:<CheckSquareOutlined />,
    show: true,
},
{
    title:'加印管理',
    path:'/admin/overprint',
    component:<Overprint/> ,
    icon:<TrophyOutlined />,
    show: true,
},

];

const menuInfo = {
    "/admin/dashboard" :
    {
        title:'控制台',
        path:'/admin/dashboard',
        component:<Dashboard/> ,
        icon:<LineChartOutlined />,
        isPublic:true ,   //公开页面
    },
    "/admin/general" :
    {
        title:'常规管理',
        path:'/admin/general',
        component:<Category/> ,
        icon:<SettingOutlined />,
        show: true,
    },
    "/admin/general/profile" :
    {
        title:'个人配置',
        path:'/admin/general/profile',
        component:<Profile/> ,
        // icon:<OrderedListOutlined />,
        show: true,
    },
    "/admin/general/config" :
    {
        title:'基础配置',
        path:'/admin/general/config',
        component:<Config/> ,
        // icon:<GroupOutlined />,
        show: true,
    },
    "/admin/auth" :
    {
        title:'权限管理',
        path:'/admin/auth',
        // component:<Category/> ,
        icon:<UnlockOutlined />,
        show: true,
    },    
    "/admin/auth/auth" :
    {
        title:'账号权限',
        path:'/admin/auth/auth',
        component:<Auth/> ,
        // icon:<OrderedListOutlined />,
        show: true,
    },
    "/admin/auth/account" :
    {
        title:'账号管理',
        path:'/admin/auth/account',
        component:<Account/> ,
        // icon:<GroupOutlined />,
        show: true,
    },
    "/admin/set" :
    {
        title:'参赛及评审设置',
        path:'/admin/set',
        component:<Category/> ,
        icon:<ProfileOutlined />,
        show: true,
    },
    "/admin/set/category" :
    {
        title:'分类管理',
        path:'/admin/set/category',
        component:<Category/> ,
        // icon:<OrderedListOutlined />,
        show: true,
    },
    "/admin/set/template" :
    {
        title:'模板管理',
        path:'/admin/set/template',
        component:<Template/> ,
        // icon:<GroupOutlined />,
        show: true,
    },
    "/admin/set/judgeflow" :
    {
        title:'评审流程设置',
        path:'/admin/set/judgeflow',
        component:<Judgeflow/> ,
        // icon:<GroupOutlined />,
        show: true,
    },
    "/admin/set/judgequota" :
    {
        title:'评审指标设置',
        path:'/admin/set/judgequota',
        component:<Judgequota/> ,
        // icon:<GroupOutlined />,
        show: true,
    },
    "/admin/company" :
    {
        title:'公司管理',
        path:'/admin/company',
        component:<Company/> ,
        icon:<BankOutlined />,
        show: true,
    },
    "/admin/member" :
    {
        title:'用户管理',
        path:'/admin/member',
        component:<Member/> ,
        icon:<TeamOutlined />,
        show: true,
    },
    "/admin/case" :
    {
        title:'案例管理',
        path:'/admin/case',
        component:<Case/> ,
        icon:<ProjectOutlined />,
        show: true,
    },
    "/admin/invoice" :
    {
        title:'开票管理',
        path:'/admin/invoice',
        component:<Invoice/> ,
        icon:<AccountBookOutlined />,
        show: true,
    },
    "/admin/raterteam" :
    {
        title:'评委组管理',
        path:'/admin/raterteam',
        component:<Raterteam/> ,
        icon:<ApartmentOutlined />,
        show: true,
    },
    "/admin/rater" :
    {
        title:'评委管理',
        path:'/admin/rater',
        component:<Rater/> ,
        icon:<CheckSquareOutlined />,
        show: true,
    },
    "/admin/overprint" :
    {
        title:'加印管理',
        path:'/admin/overprint',
        component:<Overprint/> ,
        icon:<TrophyOutlined />,
        show: true,
    },

}

export default menuInfo;