import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Radio } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, catList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    const [groupList, setGroupList] = useState([]);
    const [loading, setLoading] = useState(false);

    var catoption = [{value:"0", label:"无"}];
    for(let v of catList){
      catoption.push({value:v.cid, label:(v.sep?" ":"")+v.sep+v.name});
    }
    console.log('catoption --------------------------- ',catoption)

    useEffect(() => {
      if(showModal){
        if(rowInfo.uid == undefined){
          form.resetFields();
        } else {
          for(let k in rowInfo){
            if(k != 'passwd')
              form.setFieldValue(k, rowInfo[k]);
          }
        }

        try {
          getApiData('A', 'A_rolegroupList', {}).then((res) => {
            console.log('A_rolegroupList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              var list = [];
              for(let v of res.data.d.list){
                list.push({value:v.rid, label:(v.sep?" ":"")+v.sep+v.name});
              }
              setGroupList(list);
            }
          });
        } catch (error) {
          
          // return false;
        }
      } else {
        form.resetFields();
        setGroupList([]);
      }
    }, [showModal]);
    

    const onFinish = async(value)=>{
        console.log(value);
        let fun = "";
        if(rowInfo.uid == undefined){
          fun = "A_createAdmin";
        } else {
          fun = "A_updateAdmin";
          value.userid = rowInfo.uid;
        }

        try {
          setLoading(true);
          await getApiData('A', fun, value).then((res) => {
            setLoading(false);
            console.log(fun+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:5,offset:1}}
            size="small"
            initialValues={{status:"1"}}
        >
            <Form.Item
                label="所属组别"
                name="rid"
            >
              <Select
                  placeholder="请选择"
                  // onChange={onProdChange}
                  // defaultValue={"0"}
                  // allowClear
                  // style={{width:"50%"}}
                  options={groupList}
              />
            </Form.Item>
            <Form.Item
                label="用户名"
                name="account"
            >
            <Input placeholder="用户名" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="passwd"
                // rules={[
                //   {required: true,message: '密码必须6-30个字符',},
                //   {max: 30,message: '密码最多30位',},
                //   {min: 6,message: '密码至少6位',},
                //   // {pattern:^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$,message: '密码必须包含字母和数字',},
                // ]}
            >
            <Input.Password />
            </Form.Item>
            <Form.Item
                label="状态"
                name="status"
            >
              <Radio.Group style={{width:"80%"}} >
                <Space size={10}>
                <Radio value={"1"}>正常</Radio>
                <Radio value={"0"}>禁用</Radio>
                </Space>
              </Radio.Group>

            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
