import {  PlusOutlined,EditOutlined,DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Button, message, Popconfirm, Space } from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
import EditModel from './components/edit';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      search:false,
      // valueType: 'indexBorder',
      // width: 100,
      ellipsis: true,
    },
    {
      title: '用户名',
      dataIndex: 'account',
      // ellipsis: true,
      render: (text, record) => {
        return <span style={{marginLeft:record.sep?6:0}}>{record.sep}{text}</span>;
      },

      // copyable: true,
      // tip: '标题过长会自动收缩',
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: '此项为必填项',
      //     },
      //   ],
      // },
    },
    {
      title: '电子邮箱',
      dataIndex: 'email',
      search:false,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      search:false,
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      valueType: 'dateTime',
      width: 150,
      search:false,
    },
    {
      title: '额外案例数量',
      dataIndex: 'extcasecnt',
      search:false,
    },
    {
      disable: true,
      title: '状态',
      dataIndex: 'status',
      width: 100,
      filters: true,
      onFilter: true,
      ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "0": {
          text: '禁用',
          status: 'Default',
        },
        "1": {
          text: '正常',
          status: 'Success',
          // disabled: true,
        },
      },
    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {delMember(record.uid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </Popconfirm>
      // <a href={record.url} target="_blank" rel="noopener noreferrer" key="view">
        //   查看
        // </a>,
        // <TableDropdown
        //   key="actionGroup"
        //   onSelect={() => action?.reload()}
        //   menus={[
        //     { key: 'copy', name: '复制' },
        //     { key: 'delete', name: '删除' },
        //   ]}
        // />,
      ],
    },
  ];

  const delMember = (uid) =>{
    try {
      getApiData('A', 'A_delMember', {uid:uid}).then((res) => {
        console.log('A_delMember-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  // 导出
  const downList = () =>{
    var uid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_memberDownload', {uid:uid}).then((res) => {
        console.log('A_memberDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }

  return (
    <div>
          
    <ProTable
      headerTitle="会员管理"
      rowKey="uid"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log('params, sort, filter -------------------- ');
        console.log(params);
        console.log(sort);
        console.log(filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_memberList', params).then((res) => {
            console.log('A_memberList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={{
        labelWidth: 'auto',
      }}
      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:30
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={24}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
          </Space>
        );
      }}
      // tableAlertOptionRender={() => {
      //   return (
      //     <Space size={16}>
      //       <a><EyeOutlined />设为正常</a>
      //       <a><EyeInvisibleOutlined />设为隐藏</a>
      //     </Space>
      //   );
      // }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
        <Button
        key="button"
        icon={<DownloadOutlined />}
        style={{backgroundColor:"rgb(5,168,137)"}}
        onClick={async() => {
          downList();
          // downloadExcel("案例管理", columns, tablesData, "", []);
        }}
        type="primary"
      >
        导出
      </Button>,
    ]}
    />


    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};