import React, { useEffect,useState } from 'react';
import {Modal,Table } from 'antd'
import { ProTable, } from '@ant-design/pro-components';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

const screenHeight = document.body.clientHeight;
const screenWidth = document.body.clientWidth;

const App = (props) => {
    const {showModal, levelEnum, rowInfo, closeModal} = props;
    console.log('levelEnum -------- ', levelEnum);

    const [tableData, setTableData] = useState([]);

    const columns = [
        {
          title: '评委姓名',
          dataIndex: 'judgename',
          key: 'judgename',
        },
        {
          title: '评委组别',
          dataIndex: 'judgegroupname',
          key: 'judgegroupname',
        },
        {
          title: '评分阶段',
          dataIndex: 'level',
          key: 'level',
          width: 90,
          render: (text, record, index) => {
            console.log(text, levelEnum)
            var s = levelEnum[text] ? levelEnum[text].text : text;
            return (
                s
            );
          }
        },
        {
          title: '得分情况',
          key: 'score',
          dataIndex: 'score',
          width:300,
          render: (text, record, index) => {
            var s = JSON.stringify(text);
            s = s.slice(1, s.length-1).split('"').join("");
            return (
                s
            );
          }
        },
        {
          title: '最后得分',
          dataIndex: 'lastscore',
          key: 'lastscore',
          width: 90
        },
        {
          title: '评语',
          dataIndex: 'comment',
          key: 'comment',
          ellipsis: true,
        },

      ];

    useEffect(() => {
        if(showModal && rowInfo.id){
            try {
                getApiData('A', 'A_getScoreRecord', {caseid:rowInfo.id, level:""}).then((res) => {
                console.log('A_getScoreRecord-res-----------------', res);
                var flag = checkLogin('A', res.data)
                if (flag) {
                    setTableData(res.data.d.list);
                }
                });
            } catch (error) {
                console.log('catch-error2 ---------- ', error)
                // return false;
            }
        }
        else
            setTableData([]);
      }, [showModal, rowInfo]);
  
    return (
        <Modal 
            key="scoredetail"
            title={'得分详情'}
            open={showModal}
            width={900}
            bodyStyle={{height:screenHeight*0.8,overflowY:"scroll"}}
            centered
            footer={false}
            onCancel={closeModal}>

            <Table size="small" pagination={false} columns={columns} dataSource={tableData} />

        </Modal>
    )
}

export default App