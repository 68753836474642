import { DownloadOutlined, PlusOutlined,EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Button, Space,Popconfirm,message} from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
import EditModel from './components/edit';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'

const screenHeight = document.body.clientHeight;


export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [levellist, setLevellist] = useState([]);
  const [levelEnum, setLevelEnum] = useState({});
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
  }
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      search: false,
      // valueType: 'indexBorder',
      // width: 10,
    },
    // {
    //   title: '阶段',
    //   dataIndex: 'levelname',
    //   // width: 20,
    // },
    {
      title: '阶段',
      dataIndex: 'level',
      // width: 20,
      valueType: 'select',
      valueEnum: levelEnum,
    },
    {
      title: '评委组名',
      dataIndex: 'name',
      // width: 30,
    },
    {
      title: '操作',
      valueType: 'option',
      // width: 10,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.jid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
    ],
    },
  ];

  useEffect(() => {
      getLevellist();
  }, []);



  // 获取评选阶段列表
  const getLevellist = ()=>{
      try {
        getApiData('A', 'A_getJudgeLevel', {}).then((res) => {
          console.log('A_getJudgeLevel-res-----------------', res);
          var flag = checkLogin('A', res.data)
          var level = [{value:"", label:"--请选择--"}];
          if (flag) {
            var valueEnum = {};
            for(let k in res.data.d.levellist){
              level.push({value:k, label:res.data.d.levellist[k]});
              valueEnum[k] = {text:res.data.d.levellist[k]};
            }
            setLevelEnum(valueEnum);
            setLevellist(level);
          }
        });
      } catch (error) {
        
        // return false;
      }

  }

  const downList = () =>{
    var jid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_judgegroupDownload', {jid:jid}).then((res) => {
        console.log('A_judgegroupDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }
 

  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delJudgegroup', {jid:id}).then((res) => {
        console.log('A_delJudgegroup-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  return (
    <div>
          
    <ProTable
      headerTitle="评委组管理"
      rowKey="jid"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log(sort, filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_judgegroupList', params).then((res) => {
            console.log('A_judgegroupList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      editable={{
        type: 'multiple',
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 300,
        },
      }}
      // style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      // scroll={{ y: screenHeight>900? screenHeight-500:screenHeight-400,x:3000}}

      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:5
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={24}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
          </Space>
        );
      }}
      tableAlertOptionRender={() => {
        // return (
        //   <Space size={16}>
        //     <a><EyeOutlined />设为正常</a>
        //     <a><EyeInvisibleOutlined />设为隐藏</a>
        //   </Space>
        // );
      }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
        <Button
        key="button"
        icon={<DownloadOutlined />}
        style={{backgroundColor:"rgb(5,168,137)"}}
        onClick={async() => {
          downList();
          // downloadExcel("案例管理", columns, tablesData, "", []);
        }}
        type="primary"
      >
        导出
      </Button>,
    ]}
    />

    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      levellist={levellist}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};