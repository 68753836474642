import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Descriptions, Space,Modal,Select,Image } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    var total = 0;
    if(rowInfo.symbol){
      total = parseFloat(rowInfo.price) * parseInt(rowInfo.printcnt);
    }

    useEffect(() => {
      // var localLoginfo = localStorageGet('loginfo');
      // // console.log('localLoginfo:----------- ', localLoginfo);
      // if(localLoginfo){
      //   var loginfo_ = JSON.parse(decrypt(localLoginfo));
      //   // console.log('loginfo_--------------', loginfo_)
      //   form.setFieldValue('userName', loginfo_.userName);
      //   form.setFieldValue('password', loginfo_.password);
      //   setChecked(true);
      // }
      // else{
      //   setTimeout(()=>{
      //     form.setFieldValue('userName', "");
      //     form.setFieldValue('password', "");
      //     setChecked(false);

      //   }, 100)
      // }
    }, []);
    

    const onFinish = async(value)=>{
        console.log(value);
        let fun = "";
        if(rowInfo.cid == undefined){
          fun = "A_createCatelog";
        } else {
          fun = "A_updateCatelog";
          value.cid = rowInfo.cid;
        }

        try {
          await getApiData('A', fun, value).then((res) => {
            console.log(fun+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={"加印详情"}
        open={showModal}
        width="600px"
        centered
        footer={[] }
        onCancel={closeModal}>
        <Descriptions title="" column={1} bordered>
          <Descriptions.Item label="作品编号">{rowInfo.symbol}</Descriptions.Item>
          <Descriptions.Item label="参赛单位">{rowInfo.showcomp}</Descriptions.Item>
          <Descriptions.Item label="作品名称">{rowInfo.casename}</Descriptions.Item>
          <Descriptions.Item label="组别">{rowInfo.categoryname}</Descriptions.Item>
          <Descriptions.Item label="获奖结果">{rowInfo.result}</Descriptions.Item>
          <Descriptions.Item label="加印类别">{rowInfo.ptype}</Descriptions.Item>
          <Descriptions.Item label="logo"><Image src={rowInfo.logo} height={50}/></Descriptions.Item>
          <Descriptions.Item label="加印份额">{rowInfo.printcnt}</Descriptions.Item>
          <Descriptions.Item label="其他要求备注">{rowInfo.other}</Descriptions.Item>
        </Descriptions>
        <div style={{fontSize:18,fontWeight:550,textAlign:"center",marginTop:30}}>总计：{total} 元</div>
    </Modal>
  )
}
export default App
