import React, { useState,useEffect } from 'react';
import { SmileOutlined,DeleteOutlined } from '@ant-design/icons';

import { Button, Card,Input,Form,Progress,Image } from 'antd';
import UploadFile from '../../../components/UploadFile';

export default (props) => {
    const {label, name, formRef, initdata} = props;
    // console.log('initdata ------------------ ',initdata)
  // const [imageUrl, setImageUrl] = useState("https://kp-cloud-store.tos-cn-shanghai.volces.com/%E6%B8%B8%E6%88%8F%E5%BA%93%E4%B8%9A%E5%8A%A1/public/apks/62fb66acc287533b6d59284e_d63d19c9b3d9220b85dc23a5caebc9ab.png");
  // const [imageUrl, setImageUrl] = useState("");
  const [attpercent, setAttpercent] = useState(0);
  const [attpercentShow,setAttpercentShow] = useState(false);
 
  const seturl = (url) =>{
    console.log('url ------------------ ',url)
    // setImageUrl(url);
    formRef?.current?.setFieldValue(name, url);
  }

  
  useEffect(() => {
    if(initdata)
      seturl(initdata);
  }, [initdata]);

  
  return (
    <Form.Item
      label={label}
      style={{
        paddingTop:20,
        // paddingBottom:10,
        marginBottom: 20,
        backgroundColor:"rgb(246,246,247"
      }}
    >
      <Form.Item
        name={name}
        // validateStatus="error"
        // help="Please select right date"
        style={{
          display: 'inline-block',
          width: 'calc(80%)',
        }}
        // initialValue={imageUrl}
      >
        <Input />
      </Form.Item>
      <Form.Item
        style={{
          display: 'inline-block',
          width: 'calc(10%)',
          marginLeft:20,
        }}
      >
      <UploadFile 
        from = {"A"}
        upButton = {<Button>上传</Button>}
        uploadFileType = {".pdf, .doc, .docx"}
        uploadFileSize = {100*1024*1024}
        setpercent = {setAttpercent}
        setPercentShow = {setAttpercentShow}
        setFileUrl = {seturl}
      />

      </Form.Item>
      <Progress percent={attpercent} strokeWidth={12} style={{top:-15,display:attpercentShow?'block':'none'}}/>
      {/* {imageUrl ? (
        <div style={{width:100}}>
        <Image
          width={"100%"}
          src={imageUrl}
        />
        <Button danger type='primary' 
          style={{width:"100%", height:18, borderRadius:3, margin:"8px 0",}} 
          icon={<DeleteOutlined style={{fontSize:10}} />} 
          onClick={()=>{
            seturl("");
            setAttpercent(0);
            setAttpercentShow(false)
          }} />
        </div>
      ) : "" } */}
    </Form.Item>
    
  );
};