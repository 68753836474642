import React, { useState,useEffect,useRef }  from 'react';
import { Button, Popconfirm,message  } from 'antd';
import { PlusOutlined,EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import EditModel from './components/edit';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [catList, setCatList] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
  }

  const onChangeStatus = (cid, checked) =>{
    console.log(cid,checked)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      search:false,
      // valueType: 'indexBorder',
      // width: 100,
      ellipsis: true,
    },
    {
      title: '评选阶段',
      dataIndex: 'levelname',
      // ellipsis: true,
      render: (text, record) => {
        return <span style={{marginLeft:record.sep?6:0}}>{record.sep}{text}</span>;
      },
      // search:false

      // copyable: true,
      // tip: '标题过长会自动收缩',
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: '此项为必填项',
      //     },
      //   ],
      // },
    },
    {
      title: '评选方式',
      dataIndex: 'judgetypename',
      // width: 150,
      search:false,
    },
    // {
    //   title: '创建时间',
    //   dataIndex: 'ctime',
    //   valueType: 'dateTime',
    //   width: 150,
    //   search:false,
    // },
    // {
    //   disable: true,
    //   title: '展示状态',
    //   dataIndex: 'status',
    //   width: 100,
    //   // search:false,
    //   // filters: true,
    //   // onFilter: true,
    //   // ellipsis: true,
    //   // valueType: 'select',
    //   // valueEnum: {
    //   //   "0": {
    //   //     text: '隐藏',
    //   //     status: 'Default',
    //   //   },
    //   //   "1": {
    //   //     text: '正常',
    //   //     status: 'Success',
    //   //     // disabled: true,
    //   //   },
    //   // },
    //   render: (text, record) => {
    //     console.log('status----------',text,record)
    //     return <Switch defaultChecked={text=="1"?true:false} size="small" onChange={(checked)=>onChangeStatus(record.cid, checked)} />;
    //   },

    // },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.id)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
    ],
    },
  ];

  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delJudgetype', {id:id}).then((res) => {
        console.log('A_delJudgetype-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  return (
    <div>
          
    <ProTable
      headerTitle="评审流程"
      rowKey="id"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        var tableData = [];
        try {
          await getApiData('A', 'A_getJudgetypeList', params).then((res) => {
            console.log('A_getJudgetypeList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={false}
      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={false}
      // pagination={{
      //   pageSize: 5,
      //   onChange: (page) => console.log(page),
      // }}
      // dateFormatter="string"
      // rowSelection={{
      //   // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
      //   // 注释该行则默认不显示下拉选项
      //   // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
      //   defaultSelectedRowKeys: [],
      // }}
      // tableAlertRender={({
      //   selectedRowKeys,
      //   selectedRows,
      //   onCleanSelected,
      // }) => {
      //   console.log(selectedRowKeys, selectedRows);
      //   return (
      //     <Space size={24}>
      //       <span>
      //         已选 {selectedRowKeys.length} 项
      //         <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
      //           取消选择
      //         </a>
      //       </span>
      //       {/* <span>{`容器数量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.containers,
      //         0,
      //       )} 个`}</span>
      //       <span>{`调用量: ${selectedRows.reduce(
      //         (pre, item) => pre + item.callNumber,
      //         0,
      //       )} 次`}</span> */}
      //     </Space>
      //   );
      // }}
      // tableAlertOptionRender={() => {
      //   return (
      //     <Space size={16}>
      //       <a><EyeOutlined />设为正常</a>
      //       <a><EyeInvisibleOutlined />设为隐藏</a>
      //     </Space>
      //   );
      // }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
      ]}
    />


    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      catList={catList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};