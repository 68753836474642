import React, { useEffect,useState,useRef } from 'react';
// import UEditor from '../../components/UEditor';
import {message,Upload, } from 'antd'
import CryptoJs from 'crypto-js';
import axios from 'axios';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import ImgCrop from 'antd-img-crop';
// import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../../utils/global.js';
// import COS from 'cos-nodejs-sdk-v5';
// const COS = require('cos-nodejs-sdk-v5');
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let cancel = null;

const App = (props) => {
    const {from, upButton, uploadFileType, uploadFileSize, setpercent, setPercentShow, setFileUrl, show, upname} = props;
      console.log('uploadfile - ', show, cancel)

    useEffect(() => {
      console.log('uploadfile2222 - ', show, cancel)
      if(!show && cancel!=null){
        console.log('结束请求', source);
        cancel('Upload canceled by the user.');
      }
    }, [show]);

  /**
   * @en-US International configuration
   * @zh-CN 国际化配置
   * */
  const beforeUpload = async (file) => {
    // 获取文件信息
    console.log('file==================', file)

    let fileType = file.name;
    var index = fileType.lastIndexOf('.');
    fileType = uploadFileType.indexOf(fileType.substring(index + 1, fileType.lenght).toLowerCase()) >= 0;

    // 校验
    if (!fileType) {
      message.error('只能上传 '+uploadFileType+' 格式文件!');
    }
    if (file.size > uploadFileSize) {
      message.error('文件大小超过 '+uploadFileSize/1024/1024+'M');
      return false;
    }
    
    return fileType;
  };

  //   // 重点-上传方法
  // const uploadFile = async (res, durl, file) => {
  //   console.log('res ----------',res);

  //   // 获取后端定义好的存储路径  
  //   var storagePath = '';  

  //   // 获取腾讯云存储对象和证书信息  
  //   // var cos = new COS({
  //   //   getAuthorization: function(options, callback) {  
  //   //     callback({  
  //   //       TmpSecretId: res.TmpSecretId,  
  //   //       TmpSecretKey: res.TmpSecretKey,  
  //   //       XCosSecurityToken: res.token  
  //   //     });  
  //   //   }  
  //   // });  
      
  //   // // 上传文件到腾讯云COS  
  //   // cos.putObject({  
  //   //   Bucket: 'TopDigital', // 存储桶名称  
  //   //   Region: 'ap-beijing', // 存储桶所在地域  
  //   //   Key: storagePath + durl, // 文件存储路径和名称  
  //   //   Body: file, // 文件对象，例如使用<input type="file">元素获取的文件对象  
  //   //   onProgress: function(progressData) {  
  //   //     // 上传进度回调函数，可以用于更新上传进度条等操作  
  //   //     console.log('onProgress ------------------------- ',JSON.stringify(progressData));  
  //   //   },  
  //   //   success: function(response) {  
  //   //     // 上传成功回调函数，可以用于处理上传成功后的操作，例如将文件信息保存到数据库等  
  //   //     console.log('response -------------------- ', response);  
  //   //   },  
  //   //   fail: function(error) {  
  //   //     // 上传失败回调函数，可以用于处理上传失败后的操作，例如弹出错误提示信息给用户等  
  //   //     console.log('error -------------------------- ', error);  
  //   //   }  
  //   // });

  // }

  
    // 重点-上传方法
    const uploadFile = async (res,data) => {
      console.log('res ----------',res);
      console.log('data ----------',data);
  
      var reopt = {
        method : 'PUT',
        url : res.url,
        withCredentials: false, 
        // headers : res.requestHeaders || {},
        headers: {
          'Content-Type': res.contenttype,
          // 'content-Type': 'text/plain',
          // 'content-Type': 'multipart/form-data ',
        }, 
  
        validateStatus: function(status){
                return status >= 200;
        },
        maxRedirects : 0,
        // responseType : 'text',
        data : data.file,
        onUploadProgress: (data) => {
            let { total, loaded } = data;
            let percent = parseInt(loaded / total * 100) > 99 ? 100 : parseInt(loaded / total * 100);
            console.log('percent --------- : ', percent)
            setpercent(percent)
        }
      };
      reopt.cancelToken = new CancelToken(function executor(c) {
        // executor 函数接收一个 cancel 函数作为参数
        cancel = c;
      });
      console.log('reopt --------- ', reopt)
  
      axios.request(reopt).then(function (response) {
          console.log('==========================', response)
          if(response.status < 300){                     
                console.log('Creating object using temporary signature succeed.');
                setFileUrl(res.viewurl);
                // form.setFields([
                //   {
                //     name:'appIcon',value:res.fileUrl,errors:null
                //   }
                // ])
          }else{
            message.error("上传失败");
            console.log('Creating object using temporary signature failed!');
            console.log('status:' + response.status);
            setPercentShow(false);
            setpercent(0)
          }
          console.log(response.data);
          console.log('\n');
      }).catch(function (err) {
        console.log('Creating object using temporary signature failed22222222222222!');
        console.log(err);
        setPercentShow(false);
        setpercent(0)
    if(err.message != 'Upload canceled by the user.')
          message.error("上传失败！");
      });
  
    }
  

  const upload = async (data) => {
    const file = data.file;
      setPercentShow(true)
      const { size, type, name ,lastModified} = file 
      const strfile = name+size+type+lastModified;

      // const spark = new SparkMD5()
      // spark.append(strfile)
      // const hash = spark.end() // 获取到的文件md5值
      const hash = CryptoJs.MD5(strfile).toString();
      var durl = hash+name.substring(name.lastIndexOf("."));
      console.log('durl --------- ', durl);

      // var currentUserinfo = localStorageGet('UserInfo');
      // console.log("currentUserinfo----------------", currentUserinfo);
      // durl = currentUserinfo.id+"_"+durl;

      try {
        var fun = from=='A' ? 'A_getAliPreSignedUrl' : 'U_getAliPreSignedUrl';
        await getApiData(from, fun, {key:durl, ContentType:type}).then((res) => {
          console.log(fun+'-res-----------------', res);
          var flag = checkLogin(from, res.data)
          if (flag) {
            uploadFile(res.data.d, data)
          }
        });
      } catch (error) {
        
        // return false;
      }

      // try {
      //   var p =  { gameid: "62fb66acc287533b6d59284e", token: "a069202a0b3ad3fd0a050e1fa14f7eaf:da0e4d68b12a497327270aebca173c2a", objectKey:durl};
      //   console.log('p---------', p);
      //   getuploadurl(p).then((res) => {
      //     console.log('getuploadurl---------', res);
      //     if(res.data == undefined){
      //       message.error('接口错误');
      //       return;
      //     }
          
      //   //   var flag = checkLogin(res.data)
      //   //   if (flag) {
      //   if(res.data.code == 9999){
      //       uploadFile(res.data.data, data);
      //     }
      //   });
      //   } catch (error) {
      //     console.log('api-catch: ', error)
      //     message.error('服务错误');
      //   }
  }


    const uploadProps = {
        // action: '/upload',
        // customRequest: customRequest,
        name: 'file',
        customRequest: upload,  //  重新定义上传方法
        accept: uploadFileType,
        multiple: false,
        beforeUpload: beforeUpload,
        onStart(file) {
            console.log('onStart', file, file.name);
          },
        onChange(info) {
            console.log('info ---------- ', info)
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // actionRef.current.reload();
                message.success(`${info.file.name} 上传成功`);
                // setModalVisible(false);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
            }
        },
    };

  
    return (
      upname=='banner' ? 
        <ImgCrop aspect={1080/500} modalWidth={800}>
          <Upload {...uploadProps}>
              {upButton}
          </Upload>
        </ImgCrop>
      :
        <Upload {...uploadProps}>
            {upButton}
        </Upload>

    )
}

export default App