import { EditOutlined,DownloadOutlined,DeleteOutlined,PlusOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Space, Image, Button, message, Popconfirm } from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
import EditModel from './components/edit';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'

// const screenHeight = document.body.clientHeight;


export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [notetypeList, setNotetypeList] = useState([]);
  // const [notetypeEnum, setNotetypeEnum] = useState({});
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    getSelectlist();
  }, []);

  // 获取开票类型
  const getSelectlist = ()=>{
    try {
      getApiData('A', 'A_getSelectList', {ltype:["notetype"]}).then((res) => {
        console.log('A_getSelectList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        var list = [];
        var Enum = {};
        if (flag) {
          for(let item of res.data.d.list.notetype){
            list.push({value:item.name, label:item.name});
            Enum[item.name] = {text:item.name};
          }
          // setNotetypeEnum(Enum);
          setNotetypeList(list);
        }
      });
    } catch (error) {
      
      // return false;
    }

  }

  // 导出
  const downList = () =>{
    var nid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_noteDownload', {nid:nid}).then((res) => {
        console.log('A_noteDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }

  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delNote', {nid:id}).then((res) => {
        console.log('A_delNote-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  const closeModal = () =>{
    setShowModal(false);
  }
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      // valueType: 'indexBorder',
      width: 80,
      search: false
    },
    {
      title: '公司ID',
      dataIndex: 'companydbid',
      // valueType: 'indexBorder',
      width: 80,
      search: false
    },
    {
      title: '公司全称',
      dataIndex: 'cname',
      ellipsis:true,
      // valueType: 'indexBorder',
      width: 200,
    },
    {
      title: '公司简称',
      dataIndex: 'sname',
      ellipsis:true,
      // valueType: 'indexBorder',
      width: 150,
    },
    // {
    //   title: '栏目类型',
    //   dataIndex: 'type',
    //   width: 100,
    // },
    {
      title: '抬头',
      dataIndex: 'title',
      ellipsis:true,
      width: 160,
    },
    {
      title: '税号',
      dataIndex: 'taxcode',
      width: 160,
      ellipsis:true,
      search: false
    },
    // {
    //   title: '银行',
    //   dataIndex: 'bank',
    //   width: 160,
    //   ellipsis:true,
    //   search: false
    // },
    // {
    //   title: '账户',
    //   dataIndex: 'bankaccount',
    //   width: 160,
    //   ellipsis:true,
    //   search: false
    // },
    // {
    //   title: '地址',
    //   dataIndex: 'address',
    //   // width: 82,
    //   ellipsis:true,
    //   search: false
    // },
    // {
    //   title: '电话',
    //   dataIndex: 'phone',
    //   width: 100,
    //   ellipsis:true,
    //   search: false
    // },
    {
      title: '开票内容',
      dataIndex: 'content',
      ellipsis:true,
      width: 100,
      search: false
    },
    {
      title: '开票类型',
      dataIndex: 'invoicetype',
      width: 120,
      search: false,
      ellipsis:true,
      valueType: 'select',
      valueEnum: {
        "1": {
          text: '增值税普通发票',
          // status: 'Default',
        },
        "2": {
          text: '增值税专用发票',
          // status: 'Success',
          // disabled: true,
        },
      },

    },
    {
      title: '开票金额',
      dataIndex: 'fee',
      ellipsis:true,
      width: 80,
      search: false
    },
    {
      title: '发票形式',
      dataIndex: 'invoiceform',
      width: 80,
      search: false,
      valueType: 'select',
      valueEnum: {
        "1": {
          text: '电子发票',
          // status: 'Default',
        },
        "2": {
          text: '纸质发票',
          // status: 'Success',
          // disabled: true,
        },
      },
    },
    // {
    //   title: '收件人',
    //   dataIndex: 'recipient',
    //   width: 80,
    //   search: false
    // },
    // {
    //   title: '快递地址',
    //   dataIndex: 'postaddress',
    //   // width: 50,
    //   ellipsis:true,
    //   search: false
    // },
    // {
    //   title: '收件人电话',
    //   dataIndex: 'recipientphone',
    //   width: 100,
    //   ellipsis:true,
    //   search: false
    // },
    {
      disable: true,
      title: '付款凭证',
      dataIndex: 'voucher',
      width: 100,
      search: false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "1": {
          text: '不需要',
          // status: 'Default',
        },
        "2": {
          text: '付款通知',
          // status: 'Success',
          // disabled: true,
        },
        "3": {
          text: '合作协议',
          // status: 'Success',
          // disabled: true,
        },
      },
    },
    {
      title: '提交时间',
      dataIndex: 'ctime',
      width: 150,
      ellipsis:true,
    },
    {
      title: '营业执照',
      dataIndex: 'qualification',
      width: 80,
      search: false,
      render: (text) => {
        if (!text) {
          return <span> </span>;
        } else {
          return <Image src={text} width={35} height={35} />;
        }
      }, //这里放后台返回的图片的路径或者整个<img/>
    },
    {
      title: '跟进人',
      dataIndex: 'follow',
      width: 100,
    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.nid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
      // <a href={record.url} target="_blank" rel="noopener noreferrer" key="view">
        //   查看
        // </a>,
        // <TableDropdown
        //   key="actionGroup"
        //   onSelect={() => action?.reload()}
        //   menus={[
        //     { key: 'copy', name: '复制' },
        //     { key: 'delete', name: '删除' },
        //   ]}
        // />,
      ],
    },
  ];

  return (
    <div>
          
    <ProTable
      headerTitle="开票管理"
      rowKey="id"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log(sort, filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_noteList', params).then((res) => {
            console.log('A_noteList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      editable={{
        type: 'multiple',
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 300,
        },
      }}
      style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      scroll={{x:2250}}
      // scroll={{ y: screenHeight>900? screenHeight-500:screenHeight-400,x:3000}}

      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:30
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={24}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            {/* <Button type="primary" size="small" onClick={()=>{onNoteDownload(selectedRowKeys)}} >导出</Button> */}
            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
          </Space>
        );
      }}
      tableAlertOptionRender={() => {
        // return (
        //   <Space size={16}>
        //     <a><EyeOutlined />设为正常</a>
        //     <a><EyeInvisibleOutlined />设为隐藏</a>
        //   </Space>
        // );
      }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
        <Button
          key="button"
          icon={<DownloadOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            downList();
            // downloadExcel("案例管理", columns, tablesData, "", []);
          }}
          type="primary"
        >
          导出
        </Button>,
      ]}
    />

    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      notetypeList={notetypeList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};