import { DownloadOutlined, PlusOutlined,EditOutlined,UploadOutlined,DeleteOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Button, Space, Select,Upload,message,Popconfirm } from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
import EditModel from './components/edit';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import AppConfig from "../../app.config";
const { Option } = Select;

const screenHeight = document.body.clientHeight;


export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};
// var tableData = [
//   {
//     "jid": "1",
//     "ctime": "2023-10-09",
//     "name": "YALI",
//     "status": "1",
//     "groupid": "1",
//     "cuid": "11",
//     "groupname": "初评1组"
//   },
//   {
//     "jid": "2",
//     "ctime": "2023-10-09",
//     "name": "石丽敏",
//     "status": "1",
//     "groupid": "2",
//     "cuid": "11",
//     "groupname": "复评1组"
//   },
//   {
//     "jid": "3",
//     "ctime": "2023-10-09",
//     "name": "Nicole",
//     "status": "0",
//     "groupid": "3",
//     "cuid": "11",
//     "groupname": "终评1组"
//   },
// ];

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [judgegroupList, setJudgegroupList] = useState([]);
  const [selectedGroupid, setSelectedGroupid] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [levelEnum, setLevelEnum] = useState({});

  const closeModal = () =>{
    setShowModal(false);
  }
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      search: false,
      // valueType: 'indexBorder',
      // width: 6,
    },
    {
      title: '用户名',
      dataIndex: 'name',
      // valueType: 'indexBorder',
      // width: 20,
    },
    {
      title: '阶段',
      dataIndex: 'level',
      search: false,
      // width: 100,
      valueType: 'select',
      valueEnum: levelEnum,
    },
    {
      title: '评委组',
      dataIndex: 'groupname',
      // width: 30,
    },
    {
      disable: true,
      title: '是否激活',
      dataIndex: 'status',
      // width: 12,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      valueType: 'select',
      valueEnum: {
        "0": {
          text: '否',
          status: 'Default',
        },
        "1": {
          text: '是',
          status: 'Success',
          // disabled: true,
        },
      },
    },
    {
      title: '打分进度',
      dataIndex: 'process',
      // width: 30,
    },
    {
      title: '操作',
      valueType: 'option',
      // width: 10,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.jid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
    ],
    },
  ];

  
  useEffect(() => {
      // getJudgegroupList();
      getSelectlist();
  }, []);
  

  // 获取相关列表
  const getSelectlist = async ()=>{
    try {
      await getApiData('A', 'A_getSelectList', {ltype:["level","judgegroup"]}).then((res) => {
        console.log('A_getSelectList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {

          // 评委分组
          var judgegroup = [{value:"", label:"--请选择--"}];
          // var valueEnum = {};
          for(let item of res.data.d.list.judgegroup){
            judgegroup.push({value:item.jid, label:item.name});
            // valueEnum[item.jid] = {text:item.name};
          }
          setJudgegroupList(judgegroup);
          // setJudgegroupEnum(valueEnum);

          // 评选阶段
          // var level = [{value:"", label:"--请选择--"}];
          var valueEnum = {};
          for(let k in res.data.d.list.level){
            // level.push({value:k, label:res.data.d.list.level[k]});
            valueEnum[k] = {text:res.data.d.list.level[k]};
          }
          setLevelEnum(valueEnum);
          // setLevellist(level);

        }
      });
    } catch (error) {
      console.log('catch-error------------', error)
      // return false;
    }

  }


  // 获取评委组列表
  const getJudgegroupList = ()=>{
      try {
        getApiData('A', 'A_judgegroupList', {}).then((res) => {
          console.log('A_judgegroupList-res-----------------', res);
          var flag = checkLogin('A', res.data)
          var level = [{value:"", label:"--请选择--"}];
          if (flag) {
            for(let item of res.data.d.list){
              level.push({value:item.jid, label:item.name});
            }
            setJudgegroupList(level);
          }
        });
      } catch (error) {
        
        // return false;
      }
  }

  // 批量修改所属评委组
  const updateJudgeToGroup = (selectedRowKeys)=>{
    // console.log('selectedGroupid --------------------- ', selectedGroupid)
    // console.log('selectedRowKeys --------------------- ', selectedRowKeys)
    if(!selectedGroupid)
      return false;

    try {
      getApiData('A', 'A_updateJudgeToGroup', {jid:selectedRowKeys, groupid:selectedGroupid}).then((res) => {
        console.log('A_updateJudgeToGroup-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  // 批量修改状态
  const updateJudgeStatus = (selectedRowKeys)=>{
    // console.log('selectedGroupid --------------------- ', selectedGroupid)
    // console.log('selectedRowKeys --------------------- ', selectedRowKeys)

    try {
      getApiData('A', 'A_updateJudgeStatus', {jid:selectedRowKeys, status:selectedStatus}).then((res) => {
        console.log('A_updateJudgeStatus-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  const downList = () =>{
    var jid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_judgesDownload', {jid:jid}).then((res) => {
        console.log('A_judgesDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }
  const handleChange = (info) => {
    console.log('info -------------- ', info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      message.success("导入成功");
      actionRef.current?.reload();
    }
  };

  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delJudges', {jid:id}).then((res) => {
        console.log('A_delJudges-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  return (
    <div>
          
    <ProTable
      headerTitle="评委管理"
      rowKey="jid"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log(sort, filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_judgeList', params).then((res) => {
            console.log('A_judgeList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      editable={{
        type: 'multiple',
      }}
      columnsState={{
        persistenceKey: 'pro-table-singe-demos',
        persistenceType: 'localStorage',
        onChange(value) {
          console.log('value: ', value);
        },
      }}
      search={{
        labelWidth: 'auto',
      }}
      options={{
        setting: {
          listsHeight: 300,
        },
      }}
      // style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      // scroll={{ y: screenHeight>900? screenHeight-500:screenHeight-400,x:3000}}

      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
        columnWidth:5
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        // console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={30}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
            <Space size={10}>
              是否激活：
              <Select
                // placeholder="请选择选择栏目"
                onChange={(value)=>setSelectedStatus(value)}
                value={selectedStatus}
                // allowClear
                // style={{width:"50%"}}
              >
                  <Option value="0">否</Option>
                  <Option value="1">是</Option>
              </Select>
              <Button type="primary" onClick={()=>{updateJudgeStatus(selectedRowKeys)}}>提交</Button>
            </Space>
            <Space size={10}>
              评委组：
              <Select
                // placeholder="请选择评委组"
                onChange={(value)=>setSelectedGroupid(value)}
                value={selectedGroupid}
                options={judgegroupList}
                // allowClear
                // style={{width:"50%"}}
              />
              <Button type="primary" onClick={()=>{updateJudgeToGroup(selectedRowKeys)}} disabled={selectedGroupid?false:true}>提交</Button>
            </Space>
          </Space>
        );
      }}
      tableAlertOptionRender={() => {
        return (
          <Space size={5} style={{marginRight:50}}>
          </Space>
        );
      }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<PlusOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            // actionRef.current?.reload();
            setRowInfo({});
            await waitTime(100);
            setShowModal(true)
          }}
          type="primary"
        >
          新建
        </Button>,
        <Upload
        name="uploadfile"
        // listType="picture-card"
        // className="avatar-uploader"
        showUploadList={false}
        action={AppConfig.INTER_IMPORT_RATER + localStorage.getItem('token')}
        // beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {/* {uploadButton} */}
      <Button
        key="button"
        icon={<UploadOutlined />}
        style={{backgroundColor:"rgb(152,100,201)", borderColor:"rgb(152,100,201)"}}
        onClick={async() => {
          // actionRef.current?.reload();
          // setRowInfo({});
          // await waitTime(100);
          // setShowModal(true)
        }}
        type="primary"
        loading={loading}
      >
        导入
      </Button>
      </Upload>,
        <Button
        key="button"
        icon={<DownloadOutlined />}
        style={{backgroundColor:"rgb(5,168,137)"}}
        onClick={async() => {
          downList();
          // downloadExcel("案例管理", columns, tablesData, "", []);
        }}
        type="primary"
      >
        导出
      </Button>,
    ]}
    />

    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      judgegroupList={judgegroupList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    </div>
  );
};