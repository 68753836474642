import { DownloadOutlined,DeleteOutlined,EditOutlined,FolderViewOutlined } from '@ant-design/icons';
import { ProTable, } from '@ant-design/pro-components';
import { Button,  Space, InputNumber,Popconfirm, message, Image } from 'antd';
import React, { useState,useEffect,useRef }  from 'react';
import EditModel from './components/edit';
import DetailModel from './components/detail';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

export default () => {
  const actionRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  // const [rewardlist, setRewardlist] = useState([]);
  const [rewardEnum, setRewardEnum] = useState({});
  const [printtypeList, setPrinttypeList] = useState([]);
  const [printtypeEnum, setPrinttypeEnum] = useState({});
  const [printIndex, setPrintIndex] = useState("");
  const [pagination, setPagination] = useState({current:1,pageSize:10,total:0});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
    setShowDetail(false);
  }

  useEffect(() => {
    getSelectlist();
  }, []);



  // 获取奖项列表、加印内容列表
  const getSelectlist = ()=>{
      try {
        getApiData('A', 'A_getSelectList', {ltype:["reward","printtype"]}).then((res) => {
          console.log('A_getSelectList-res-----------------', res);
          var flag = checkLogin('A', res.data)
          var reward = [];
          var rewardenum = {};
          var printtype = [];
          var printtypeenum = {};
          if (flag) {
            for(let item of res.data.d.list.reward){
              reward.push({value:item.name, label:item.name});
              rewardenum[item.name] = {text:item.name};
            }
            setRewardEnum(rewardenum);
            // setRewardlist(reward);
            for(let item of res.data.d.list.printtype){
              printtype.push({value:item.name, label:item.name});
              printtypeenum[item.name] = {text:item.name};
            }
            setPrinttypeEnum(printtypeenum);
            setPrinttypeList(printtype);
          }
        });
      } catch (error) {
        
        // return false;
      }

  }


  // 批量修改加印批次
  const updatePrintIndex = (selectedRowKeys)=>{
    if(!printIndex || selectedRowKeys.length==0)
      return false;

    try {
      getApiData('A', 'A_setPrintIndex', {pid:selectedRowKeys, printindex:printIndex}).then((res) => {
        console.log('A_setPrintIndex-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }

  // 删除一行数据
  const deleteRow = (id)=>{
    console.log('deleteRow ------------- ', id)
    if(!id)
      return false;

    try {
      getApiData('A', 'A_delPrint', {pid:id}).then((res) => {
        console.log('A_delPrint-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          message.success("操作成功");
          actionRef.current?.reload();
        }
      });
    } catch (error) {
      
      // return false;
    }
  }


  const downList = () =>{
    var pid = selectedRowKeys.length==0 ? "" : selectedRowKeys.join(",");
    try {
      getApiData('A', 'A_printinfoDownload', {pid:pid}).then((res) => {
        console.log('A_printinfoDownload-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          window.location.href=res.data.d.url;
        }
      });
    } catch (error) {

      // return false;
    }
  }

  const downLogoList = () =>{
    try {
      getApiData('A', 'A_logoDownloadList', {type:"print"}).then((res) => {
        console.log('A_logoDownloadList-res-----------------', res);
        var flag = checkLogin('A', res.data)
        if (flag) {
          if(res.data.d.list.length == 0){
            message.error("无logo信息");
            return;
          }
          downloadImages(res.data.d.list);
        }
      });
    } catch (error) {

      // return false;
    }
  }

  const downloadImages = async (listinfo) => {
    const zip = new JSZip();
    const imgPromises = listinfo.map(async (info, index) => {
      const response = await fetch(info.logo, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }});
      const blob = await response.blob();
      zip.file(info.companyid+"_"+info.sname+"_"+info.id+info.logo.substring(info.logo.lastIndexOf('.'), info.logo.length), blob);
    });
    await Promise.all(imgPromises);
 
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "加印logo.zip");
    });
  };
 
  const columns = [
    {
      title: '公司ID',
      dataIndex: 'companyid',
      ellipsis: true,
      width: 80,
      search: false,
    },
    // {
    //   title: '公司全称',
    //   dataIndex: 'companyname',
    //   ellipsis: true,
    //   width: 200,
    //   search: false,
    // },
    {
      title: '公司简称',
      dataIndex: 'companysname',
      ellipsis: true,
      width: 150,
      search: false,
    },
    {
      title: '案例ID',
      dataIndex: 'caseid',
      ellipsis: true,
      width: 100,
      search: false,
    },
    {
      title: '参赛单位',
      dataIndex: 'companyname',
      ellipsis: true,
      width: 200,
      search: false,
    },
    {
      title: '作品名称',
      dataIndex: 'casetitle',
      ellipsis: true,
      width: 200,
    },
    {
      title: '参赛单位(加印)',
      dataIndex: 'showcomp',
      ellipsis: true,
      width: 200,
      search: false,
    },
    {
      title: '作品名称(加印)',
      dataIndex: 'casename',
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: '作品编号',
    //   dataIndex: 'symbol',
    //   search:false,
    //   ellipsis: true,
    //   width: 150,
    // },
    // {
    //   title: '参赛单位(获奖)',
    //   dataIndex: 'oldshowcomp',
    //   ellipsis: true,
    //   width: 200,
    //   search: false,
    // },
    // {
    //   title: '作品名称(获奖)',
    //   dataIndex: 'oldcasename',
    //   ellipsis: true,
    //   // width: 260,
    // },
    {
      title: '参赛组别',
      dataIndex: 'categoryname',
      ellipsis: true,
      width: 200,
    },
    {
      title: '获奖结果',
      dataIndex: 'result',
      valueType: 'select',
      valueEnum: rewardEnum,
      ellipsis: true,
      width: 100,
    },
    {
      title: '加印内容',
      dataIndex: 'ptype',
      valueType: 'select',
      valueEnum: printtypeEnum,
      width: 150,
    },
    {
      title: '加印份额',
      dataIndex: 'printcnt',
      search:false,
      width: 100,
    },
    {
      title: '加印费用/元',
      dataIndex: 'price',
      search:false,
      width: 100,
    },
    // {
    //   title: '添加时间',
    //   dataIndex: 'ctime',
    //   valueType: 'dateTime',
    //   width: 150,
    //   search:false,
    // },
    // {
    //   title: '加印批次',
    //   dataIndex: 'printindex',
    //   search:false,
    //   width: 100,
    // },
    {
      title: '跟进人',
      dataIndex: 'follow',
      search:false,
      width: 100,
    },
    {
      title: '加印备注',
      dataIndex: 'other',
      search:false,
      // width: 200,
    },
    // {
    //   title: 'logo素材',
    //   dataIndex: 'logo',
    //   width: 80,
    //   search: false,
    //   render: (text) => {
    //     if (!text) {
    //       return <span> </span>;
    //     } else {
    //       return <Image src={text} width={35} height={35} />;
    //     }
    //   }, //这里放后台返回的图片的路径或者整个<img/>
    // },
    {
      title: '操作',
      valueType: 'option',
      width: 120,
      key: 'option',
      render: (text, record, _, action) => [
        <a
          key="preview"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowDetail(true);
          }}
        >
          <FolderViewOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
        </a>,
        <a
          key="editable"
          onClick={async() => {
            console.log('record ------------- ', record)
            console.log('action ------------- ', action)
            // action?.startEditable?.(record.id);
            setRowInfo(record);
            await waitTime(100);
            setShowModal(true);
          }}
        >
          <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </a>,
        <Popconfirm title="确定删除吗?" onConfirm={() => {deleteRow(record.pid)}}>
          <DeleteOutlined style={{backgroundColor:"#ff4d4f",padding:"2px 3px",borderRadius:3,color:"white", marginLeft:5}} />
        </Popconfirm>
    ],
    },
  ];

  return (
    <div>
          
    <ProTable
      headerTitle="加印管理"
      rowKey="pid"
      columns={columns}
      actionRef={actionRef}
      cardBordered
      defaultSize="small"
      request={async (params, sort, filter) => {
        console.log('params, sort, filter -------------------- ');
        console.log(params);
        console.log(sort);
        console.log(filter);
        // await waitTime(2000);
        var tableData = [];
        try {
          await getApiData('A', 'A_printList', params).then((res) => {
            console.log('A_printList-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              tableData = res.data.d.list;
              setPagination({current:parseInt(res.data.d.pageinfo.curpage), pageSize:parseInt(res.data.d.pageinfo.pagesize), total:parseInt(res.data.d.pageinfo.total)})
            }
          });
        } catch (error) {
          
          // return false;
        }
        return {
          data: tableData,
          // current: res.data.pageNo,
          // pageSize: res.data.pageSize,
          success: true,
          // total: res.data.totalRows,
        };
      }}
      // editable={{
      //   type: 'multiple',
      // }}
      // columnsState={{
      //   persistenceKey: 'pro-table-singe-demos',
      //   persistenceType: 'localStorage',
      //   onChange(value) {
      //     console.log('value: ', value);
      //   },
      // }}
      search={{
        labelWidth: 'auto',
      }}
      style={{lineHeight: '0.40rem',marginTop:'0.32rem',overflowX: 'scroll',overflowY: 'scroll'}}  
      scroll={{x:2200}}

      // options={{
      //   setting: {
      //     listsHeight: 200,
      //   },
      // }}
      // form={{
      //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
      //   syncToUrl: (values, type) => {
      //     if (type === 'get') {
      //       return {
      //         ...values,
      //         created_at: [values.startTime, values.endTime],
      //       };
      //     }
      //     return values;
      //   },
      // }}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100, pagination.total],
        showSizeChanger: true,
        pageSize: pagination.pageSize,
        current:pagination.current,
        total:pagination.total,
        onChange: (page) => console.log(page),
      }}
      // dateFormatter="string"
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        defaultSelectedRowKeys: [],
      }}
      tableAlertRender={({
        selectedRowKeys,
        selectedRows,
        onCleanSelected,
      }) => {
        console.log(selectedRowKeys, selectedRows);
        if(selectedRowKeys)
          setSelectedRowKeys(selectedRowKeys);
        return (
          <Space size={24}>
            <span>
              已选 {selectedRowKeys.length} 项
              <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                取消选择
              </a>
            </span>
            <Space size={10}>
              加印批次：
              <InputNumber value={printIndex} onChange={(value)=>{console.log(value);setPrintIndex(value)}} />
              <Button type="primary" onClick={()=>{updatePrintIndex(selectedRowKeys)}} disabled={printIndex?false:true}>提交</Button>
            </Space>

            {/* <span>{`容器数量: ${selectedRows.reduce(
              (pre, item) => pre + item.containers,
              0,
            )} 个`}</span>
            <span>{`调用量: ${selectedRows.reduce(
              (pre, item) => pre + item.callNumber,
              0,
            )} 次`}</span> */}
          </Space>
        );
      }}
      tableAlertOptionRender={() => {
        // return (
        //   <Space size={16}>
        //     <a><EyeOutlined />设为正常</a>
        //     <a><EyeInvisibleOutlined />设为隐藏</a>
        //   </Space>
        // );
      }}
      toolBarRender={() => [
        <Button
          key="button"
          icon={<DownloadOutlined />}
          style={{backgroundColor:"rgb(5,168,137)"}}
          onClick={async() => {
            downList();
            // downloadExcel("案例管理", columns, tablesData, "", []);
          }}
          type="primary"
        >
          导出
        </Button>,
        <Button
        key="button"
        icon={<DownloadOutlined />}
        style={{backgroundColor:"rgb(5,168,137)"}}
        onClick={async() => {
          downLogoList();
          // downloadExcel("案例管理", columns, tablesData, "", []);
        }}
        type="primary"
      >
        导出logo
      </Button>,
    ]}
    />


    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      printtypeList={printtypeList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />

    <DetailModel
      showModal = {showDetail}
      closeModal={closeModal}
      rowInfo={rowInfo}
    />

    </div>
  );
};