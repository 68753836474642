import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Progress, InputNumber,Modal,Select,Image } from 'antd';
// import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import UploadFile from '../../components/UploadFile';
// import { fromJS } from 'immutable';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, printtypeList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState("");
    const [attpercent, setAttpercent] = useState(0);
    const [attpercentShow,setAttpercentShow] = useState(false);
    const [loading,setLoading] = useState(false);


    useEffect(() => {
      if(showModal){
        if(rowInfo.pid == undefined){
          form.resetFields();
        } else {
          for(let key in rowInfo)
            form.setFieldValue(key, rowInfo[key])
          setImageUrl(rowInfo.logo);
        }
      } else {
        setAttpercent(0);
        setAttpercentShow(false);
      }
    }, [showModal]);
    
    // 提交
    const onFinish = async(value)=>{
        console.log(value);
        if(!rowInfo.pid)
          return false;
        
        var p =  {
          pid: rowInfo.pid,
          showcomp: value.showcomp,
          casename: value.casename,
          other: value.other,
          printcnt: value.printcnt,
          ptype: value.ptype,
          logo: imageUrl
        };
        console.log(p);
        
        try {
          setLoading(true);
          await getApiData('A', "A_printEdit", p).then((res) => {
            setLoading(false);
            console.log('A_printEdit-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:5,offset:2}}
            size="small"
            // initialValues={rowInfo}
        >
            <Form.Item
                label="作品编号"
                name="symbol"
            >
            <Input disabled />
            </Form.Item>
            <Form.Item
                label="参赛单位"
                name="showcomp"
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="作品名称"
                name="casename"
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="组别"
                name="categoryname"
            >
            <Input disabled />
            </Form.Item>
            <Form.Item
                label="获奖结果"
                name="result"
            >
            <Input disabled />
            </Form.Item>
            <Form.Item
                label="加印类别"
                name="ptype"
            >
            <Select options={printtypeList} />
            </Form.Item>
            <Form.Item
                label="logo"
                name="logo"
            >
            <Image
              height={50}
              src={imageUrl}
            />
            <UploadFile 
              from = {"A"}
              upButton = {<Button type="primary" size="middle" style={{marginLeft:20, color:"white",backgroundColor: "rgb(231,76,60)",borderColor: "rgb(231,76,60)"}} >上传</Button>}
              uploadFileType = {".png, .jpg, .jpeg, .gif"}
              uploadFileSize = {5*1024*1024}
              setpercent = {setAttpercent}
              setPercentShow = {setAttpercentShow}
              setFileUrl = {setImageUrl}
            />
            <Progress percent={attpercent} strokeWidth={6} style={{top:5,display:attpercentShow?'block':'none'}}/>

            </Form.Item>
            <Form.Item
                label="加印份额"
                name="printcnt"
            >
            <InputNumber width={"100%"} />
            </Form.Item>
            <Form.Item
                label="其他要求备注"
                name="other"
            >
            <Input />
            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
