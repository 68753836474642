import React , { useState,useEffect }from 'react'
import { Card, Col, Row, Statistic, ConfigProvider, Select,Space,Button,Spin,DatePicker} from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined,SearchOutlined } from '@ant-design/icons';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import "./index.css"
import * as echarts from 'echarts';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')
const { Option } = Select;
const { RangePicker } = DatePicker;


const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
const chartHeight = screenHeight *0.42

const App = () => {
  const [DatePickerShow,setDatePickerShow] = useState(false);
  const [isLoaded,setIsLoaded] = useState(false);
  const [consoleData,setConsoleData] = useState({});

  let dataNow =new Date().getTime()
  let frontOneHour = dataNow - 1 * 60 * 60 * 1000;
  console.log('stime:'+frontOneHour+'-' +'etime:'+dataNow)

  const [stime,setStime] = useState(frontOneHour);
  const [etime,setEtime] = useState(dataNow);

  useEffect(() => {
    getConsoleData();
  }, []);

  const getConsoleData=async()=>{
    try {
      await getApiData('A', 'A_console', {}).then((res) => {
      console.log('A_console-res-----------------', res);
      var flag = checkLogin('A', res.data)
      if (flag) {
        setConsoleData(res.data.d.info);
      }
      });
    } catch (error) {
        console.log('catch-error2 ---------- ', error)
        // return false;
    }
  }

  
  const timeChange = (LabeledValue, option) =>{
    if(LabeledValue !== ''){
        
    }else{
      setDatePickerShow(true);
    }
  }

  const dataChange = (dates,string) =>{
      console.log(string)
      setStime(new Date(string[0]).getTime());
      setEtime(new Date(string[1]).getTime());
  }

  const partnersGet =  () => {
      // this.setState({
      //     isLoaded: true
      //   })
      //   const {stime,etime,instanceId} = this.state;
        

      // Toalieip(JSON.parse(localStorage.getItem('UserInfo')).token,menu,instanceId,stime,etime).then( res=>{
      //     let data = res.data.result
      //     this.setState({
      //         isLoaded:  false,
      //         dataAll:data
      //     })
      //     this.drawChart(data,'net',['流入宽带','流出宽带'],['net_rx.rate','net_tx.rate'],'流出/入宽带(bps)')
      //     this.drawChart(data,'net_rxPkgs',['流入包速率','流出包速率'],['net_rxPkgs.rate','net_txPkgs.rate'],'流出/入包速率(pps)')
      //     this.drawChart(data,'rate',['网络流入带宽利用率','网络流出带宽利用率'],['net_in.rate_percentage','net_out.rate_percentage'],'网络出/入带宽利用率')
      //     this.drawChart(data,'ratelimit',['入方向限速丢包速率','出方向限速丢包速率'],['in_ratelimit_drop_speed','out_ratelimit_drop_speed'],'出/入方向限速丢包速率(pps)')
      //     this.setState({
      //         isLoaded: false
      //       })
      // }).catch(
      //   error => {
      //     this.setState({
      //       isLoaded: true,
      //       error
      //     })
      //   }
      // )
  };

  const drawChart = (dataarr,name,legendName,dataName,title) => {
      if(!dataarr || dataarr?.length === 0) {
          dataarr ={
              'time':[],
              'net.tx':[],
              'net.rx':[],
              'net_rxPkgs.rate':[],
              'net_txPkgs.rate':[],
              'net_in.rate_percentage':[],
              'net_out.rate_percentage':[],
              'in_ratelimit_drop_speed':[],
              'out_ratelimit_drop_speed':[],
          }
      }
      let dataZoomState=100;
      if(dataarr?.time?.length>=720){
        dataZoomState=(720/dataarr.time.length)*100;
      }
      var myChart = echarts.init(document.getElementById(name));
      let item = {
          normal: {
              areaStyle: {
                  type: 'default',
                  opacity: 0,
              },
              lineStyle: {
                  width: 1,
              }
      
          }
      }
      let charty = [{
          name :legendName[0],
          data:dataarr[dataName[0]],
          type: 'line',
          showSymbol: true,
          itemStyle: item,
          symbol: "circle", //拐点的形状
          showSymbol:false

      },{
          name :legendName[1],
          data:dataarr[dataName[1]],
          type: 'line',
          showSymbol: true,
          symbol: "circle", //拐点的形状
          itemStyle: item,
          showSymbol:false

      }]

      var option = {
          title: {
              text: title,
              left: 'left',
              x: '17px',
              y: '7px',
              textStyle: {
                  color: '#666',
                  fontSize: 13
              },
          },
      color: ['#5599FF', '#FF8C00'],
      legend: {
          data: legendName,
          x:'center',      
          y:'bottom',
          padding:[10,0,10,0],
      },
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'line',
              label: {
                  backgroundColor: '#6a7985',
                  formatter: '{value}'
              }
          }
      },
      axisLine: {
                  lineStyle: {
                      color: 'f5f5f5',
                  }
              },
      grid:{
              left:'15%',
              right:'10%',
              top:'15%',
              bottom:'15%',
              // containLabel:true
          },
      splitLine:{
          show: true,
          neStyle:{
              color: '#f5f5f5',
          }
      },
    
      xAxis: {
          type: 'category',
          boundaryGap: false, 
          data: dataarr?.time?dataarr.time:[],
          splitLine: {show: false},
          axisLabel: {
              show: true,
              textStyle: {
                  color: '#555555;'
              }
          }
      },
      yAxis: {
          type: 'value',
          splitLine: {
              show: true,
              lineStyle:{
              color: ['#f5f5f5'],
              type: 'solid',
          }
          },
          //  axisLine: { show: true, lineStyle: { color: '#dddddd' } },
          //  splitLine: { lineStyle: { type: 'dashed', color: '#eeeeee' } },
          axisLine: {
                  lineStyle: {
                      color: 'rgba(229,229,229,1 )',
                  }
          },
          axisLabel: {
              show: true,
              formatter: '{value}',
              textStyle: {
                  color: '#555555;'
              } , 
              formatter: function (val){
                  if(name === 'net'){
                      return val / 1000+ "K"
                  // }else if ( name === 'ratelimit'){
                  //     return val * 100 + "%"
                  // }else if (name === 'net_rxPkgs'){
                  //     return val * 100 + "%"
                  }
                  return val ;
              }
          }
      },
      //  //数据缩放视图
      dataZoom: [{
          show: true,
          xAxisIndex: [0],
          type: 'inside',
          top: '4%',
          height:'3%',
          start: 0,
          end: dataZoomState,
          handleIcon:
              'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
          handleSize: '90%',
          handleStyle: {
              color: '#6680FF',
              borderColor: '#6680FF',
              shadowColor: '#6680FF',
          },
          textStyle: {
              color: '#fff'
            },
          //      type: 'inside',
          //      start: 0,
          //      end: dataZoomState,
          //  }, {
          //      start: 0,
          //      end: 10
          }],
      
      //工具框，可以选择
      toolbox: {
          //  feature: {
          //      saveAsImage: {},
          //      magicType: {//动态类型切换
          //          type: ['bar', 'line']
          //      }
          //  }
      },
      series: charty
    
      }
      
      myChart.setOption(option);
      let chartBox;
      chartBox = document.getElementById('chartsBox');

      window.addEventListener('resize', function () {
          let chartBoxWidth = chartBox.offsetWidth;
          let chartBoxHeight = chartBox.offsetHeight;
          myChart.resize({width: chartBoxWidth, height: chartBoxHeight})
      })
      
  }

  // handleResize = e => {
  //   console.log('浏览器窗口大小改变事件', e.target.innerWidth,e.target.innerHeight);
  //   this.setState({screenWidth:e.target.innerWidth})
  //   this.setState({screenHeight:e.target.innerHeight})
  // }

// componentWillMount(){
//    this.partnersGet(); 
// }
// componentDidMount(){
//   window.addEventListener('resize', this.handleResize.bind(this));
// }
// componentWillUnmount() { //一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
//   window.removeEventListener('resize', this.handleResize.bind(this))
// }



// downloadExcel = (columns) => {
//     const {records}=this.state;
//     const data = records? records : '';//表格数据
//     const workbook = new Excel.Workbook();
//     workbook.creator = "admin";
//     workbook.lastModifiedBy = "admin";
//     workbook.created = new Date();
//     workbook.modified = new Date();
//      // 添加工作表
//     let fileName = '海外线路报表';
    
//     let sheet = workbook.addWorksheet("sheet");
//     let columnsList = [];
//     columns.map((item) => {
//       let title;
//       if(typeof item.title === 'string'){
//         title=item.title;
//       }else{
//         title=item.title.props.children[0];
//       }
//       columnsList.push({
//         header: title,
//         key: item.key || item.dataIndex,
//         width: 15,
//       });
//       return true;
//     });
//     // 添加表头
//     sheet.columns = columnsList;
    
//     if (Array.isArray(data)) {
//       // 添加表格数据
//       sheet.addRows(data);
//       // 设置每一列样式 居中
//       const row = sheet.getRow(1);
//       row.eachCell((cell, rowNumber) => {
//         sheet.getColumn(rowNumber).alignment = {
//           vertical: "middle",
//           horizontal: "right",
//         };
//       });

//       // 将表格数据转为二进制
//       workbook.xlsx.writeBuffer().then((buffer) => {
//         writeFile(`${fileName}.xlsx`, buffer);
//       });
//     } else {
//       alert("下载失败");
//     }
 
//    // 将二进制转为Excel并下载
//     const writeFile = (fileName, content) => {
//       let a = document.createElement("a");
//       let blob = new Blob([content], { type: "text/plain" });
//       a.download = fileName;
//       a.href = URL.createObjectURL(blob);
//       a.click();
//     };     
//   }

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <div className='imgError'></div>
      <p style={{color: '#222222',fontSize: '18px',fontFamily: 'PingFangSC-Medium'}}>暂无数据</p>
    </div>
  );

  
  return(
  <div>
    <div style={{padding: 30}}>
      <Row gutter={16}>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="今日访问量"
              value={consoleData.visitcnt}
              // precision={2}
              // valueStyle={{
              //   color: '#3f8600',
              // }}
              // prefix={<ArrowUpOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="今日注册量"
              value={consoleData.regcnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="今日新增参赛公司数"
              value={consoleData.companycnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="今日新增作品"
              value={consoleData.casecnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{marginTop:20}}>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="累计访问量"
              value={consoleData.total_visitcnt}
              // precision={2}
              // valueStyle={{
              //   color: '#3f8600',
              // }}
              // prefix={<ArrowUpOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="累计注册量"
              value={consoleData.total_regcnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="累计参赛公司数"
              value={consoleData.total_companycnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false} style={{textAlign:"center"}}>
            <Statistic
              title="累计作品"
              value={consoleData.total_casecnt}
              // precision={2}
              // valueStyle={{
              //   color: '#cf1322',
              // }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
            />
          </Card>
        </Col>
      </Row>
    </div>

    <ConfigProvider  renderEmpty={customizeRenderEmpty} >
      <Card className='weibao' style={{display:"none"}} > 
        {/* <div className='weibaoSearch'  style={{fontWeight:400,color: '#222222',fontSize:'0.16rem'}}> */}
          {/* <span>时间</span>
            <Select  defaultValue='1'  style={{width:'11.5%',marginLeft:'0.24rem',fontSize:'0.16rem'}} onSelect={timeChange}>
                <Option key='1'>1小时</Option>
                <Option key='3'>3小时</Option>
                <Option key='6'>6小时</Option>
                <Option key='12'>12小时</Option>
                <Option key=''>自定义</Option>
            </Select>
            <Space direction="vertical"  style={{width:'24%',marginLeft:'0.16rem',fontSize:'0.16rem',display:DatePickerShow?'inline-block':'none'}} size={12}>
                <RangePicker showTime  onChange={dataChange}  />
            </Space> */}
            

            {/* <Button type="primary"  style={{marginLeft:'3.3%'}} onClick={()=>{partnersGet()}} icon={<SearchOutlined  />}>查询</Button> */}
            {/* ------换行----- */}
            {/* <br></br> */}
          {/* </div> */}
          <Spin spinning={isLoaded}>
            <div id="chartsBox" style={{boxShadow: '-2px -2px 10px #ccc',height:chartHeight,width:'48%',float:'left',margin:'1%'}}>
                <div id="net" style={{width: '100%', height: '100%'}}>

                </div>
            </div>
            <div id="chartsBox1" style={{boxShadow: '-2px -2px 10px #ccc',height:chartHeight,width:'48%',float:'left',margin:'1%'}}>
                <div id="net_rxPkgs" style={{width: '100%', height: '100%'}}>

                </div>
            </div>
            <div id="chartsBox2" style={{boxShadow: '-2px -2px 10px #ccc',height:chartHeight,width:'48%',float:'left',margin:'1%'}}>
                <div id="rate" style={{width: '100%', height: '100%'}}>

                </div>
            </div>
            
            <div id="chartsBox3" style={{boxShadow: '-2px -2px 10px #ccc',height:chartHeight,width:'48%',float:'left',margin:'1%'}}>
                <div id="ratelimit" style={{width: '100%', height: '100%'}}>

                </div>
            </div>
            </Spin>
      </Card> 
    </ConfigProvider>
  </div>
)};
export default App;