import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Checkbox, Modal,Select,Tree } from 'antd';
// import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../../request/api";
import checkLogin from '../../../../request/checkLogin.js'
import "./edit.css"

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径
const screenHeight = document.body.clientHeight;
const screenWidth = document.body.clientWidth;
  
const App =(props)=>{
    const {showModal, closeModal, rowInfo, catList, refreshlist} = props;
    // console.log('1111111111111111111111111111111111111-', rowInfo, catList)
    const [form] = Form.useForm();
    const [catoption, setCatoption] = useState([]);
    const [authList, setAuthList] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [expandAll, setExpandAll] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [halfCheckedKeys, setHalfCheckedKeys] = useState(false);

    const [allTreeKeys, setAllTreeKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    // const [selectedKeys, setSelectedKeys] = useState([]);
    // const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [loading, setLoading] = useState(false);

    const onExpand = (expandedKeysValue) => {
      console.log('onExpand', expandedKeysValue);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      setExpandedKeys(expandedKeysValue);
      // setAutoExpandParent(false);
    };
    const onCheck = (checkedKeysValue, e) => {
      console.log('onCheck', checkedKeysValue);
      console.log(e)
      setCheckedKeys(checkedKeysValue);
      setHalfCheckedKeys(e.halfCheckedKeys);
    };
    // const onSelect = (selectedKeysValue, info) => {
    //   console.log('onSelect', info);
    //   setSelectedKeys(selectedKeysValue);
    // };


    
    useEffect(() => {
      if(showModal){
        console.log(catList.length)
        if(catList.length > 0){
          var option = [];
          for(let v of catList){
            let one = {value:v.rid, label:(v.sep?" ":"")+v.sep+v.name};
            if(v.rid == rowInfo.rid)
              one.disabled = true;
            option.push(one);
          }
          setCatoption(option);
          var pid = "";
          if(rowInfo.pid){
            pid = rowInfo.pid;
            form.setFieldValue("name", rowInfo.name);
          }
          else 
            pid = catList[0].rid;

          form.setFieldValue('pid', pid);
          // 获取权限
          getAuthlist(pid, rowInfo.rid);
        }
        // if(rowInfo.rid == undefined){
        //   form.resetFields();
        // } else {
        //   form.setFieldValue("pid", rowInfo.pid)
        //   form.setFieldValue("name", rowInfo.name)
        //   form.setFieldValue("key", rowInfo.key)
        // }
      }
      else {
        form.resetFields();
        setAuthList([]);
        setTreeData([]);
        setExpandAll(false);
        setCheckAll(false);
    
        setAllTreeKeys([]);
        setExpandedKeys([]);
        setCheckedKeys([]);
    
      }
    }, [showModal]);

    // 选择全部
    const onCheckAll = (value) => {
      console.log(value);
      setCheckAll(value);
      if(value)
        setCheckedKeys(allTreeKeys);
      else 
        setCheckedKeys([]);
    }

    // 展开全部
    const onExpandAll = (value) => {
      console.log(value);
      setExpandAll(value);
      if(value)
        setExpandedKeys(allTreeKeys);
      else 
        setExpandedKeys([]);
    }

    // 选择父级
    const onCatChange = (pid) => {
      console.log(pid);
      getAuthlist(pid, "");
    }

    // 获取某个角色组的权限列表
    const getAuthlist = async (rid, myrid) => {
      try {
        var p = {rid:rid};
        if(myrid)
          p.myrid = myrid;
        await getApiData('A', 'A_getRolegroupPriv', p).then((res) => {
          console.log('A_getRolegroupPriv-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            setAuthList(res.data.d.list);
            // 设置树结构
            var list = [];
            var allkeys = [];
            var select = [];
            for(let item of res.data.d.list){
              allkeys.push(item.path);
              var one = {title:item.name, key:item.path, children:[], className:"auth-noleaf"};
              if(item.sub.length > 0){
                var children = [];
                for(let sub of item.sub){
                  // console.log(sub)
                  var oplist = [];
                  for(let op of sub.op){
                    allkeys.push(sub.path+"_"+op.name);
                    oplist.push({title:op.name, key:sub.path+"_"+op.name, className:"auth-leaf"});
                    if(myrid && op.priv==1)
                      select.push(sub.path+"_"+op.name);
                  }
                  allkeys.push(sub.path);
                  children.push({title:sub.name,key:sub.path,children:oplist, className:"auth-noleaf"});
                }
                one.children = children;
              }
              else if(item.op.length > 0){
                for(let op of item.op){
                  allkeys.push(item.path+"_"+op.name);
                  one.children.push({title:op.name, key:item.path+"_"+op.name, className:"auth-leaf"});
                  if(myrid && op.priv==1)
                      select.push(item.path+"_"+op.name);
                }
              }
              list.push(one);
            }
            console.log('select -- ', select);
            setTreeData(list);
            setAllTreeKeys(allkeys);
            if(myrid)
              setCheckedKeys(select);
          }
        });
      } catch (error) {
        
        // return false;
      }
    }
    

    const onFinish = async(value)=>{
        console.log(checkedKeys);
        var priv = authList;
        if(checkedKeys.length > 0){
          for(let i in priv){
            // console.log(priv[i], checkedKeys.includes(priv[i].path))
            priv[i].priv = 0;
            if(priv[i].sub.length > 0){
              for(let j in priv[i].sub){
                priv[i].sub[j].priv = 0;
                for(let k in priv[i].sub[j].op){
                  priv[i].sub[j].op[k].priv = 0;
                  if(checkedKeys.includes(priv[i].sub[j].path+"_"+priv[i].sub[j].op[k].name)){
                    priv[i].sub[j].op[k].priv = 1;
                    priv[i].sub[j].priv = 1;
                    priv[i].priv = 1;
                  };
                }
              }
            }
            else if(priv[i].op.length > 0){
              priv[i].priv = 0;
              for(let k in priv[i].op){
                priv[i].op[k].priv = 0;
                if(checkedKeys.includes(priv[i].path+"_"+priv[i].op[k].name)){
                  priv[i].op[k].priv = 1;
                  priv[i].priv = 1;
                }
              }
            }
          }
        }
        value.priv = priv;
        console.log(priv)

        var fun = "A_createRolegroup";
        if(rowInfo.rid){
          fun = "A_updateRolegroup";
          value.rid = rowInfo.rid;
        }

        try {
          setLoading(true);
          await getApiData('A', fun, value).then((res) => {
            setLoading(false);
            console.log(fun+'-res-----------------', res);
            var flag = checkLogin('A', res.data)
            if (flag) {
              message.success("操作成功");
              closeModal();
              refreshlist();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.cid ? '编辑' :'添加'}
        open={showModal}
        width={screenWidth*0.5}
        bodyStyle={{height:screenHeight*0.7,overflowY:"scroll"}}
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>] }
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:3,offset:2}}
            size="small"
            initialValues={{pid:catList.length>0 ? catList[0].rid : ""}}
        >
            <Form.Item
                label="父级"
                name="pid"
            >
              <Select
                  // placeholder="请选择选择栏目"
                  onChange={onCatChange}
                  // defaultValue={catList.length>0 ? catList[0].rid : ""}
                  // allowClear
                  // style={{width:"50%"}}
                  // fieldNames={{value:"rid", label:"name"}}
                  options={catoption}
              />
            </Form.Item>
            <Form.Item
                label="名称"
                name="name"
                rules={[
                  {required: true, message: '请输入名称',},
                ]}
            >
            <Input placeholder="名称" />
            </Form.Item>
            <Form.Item
                label="权限"
                // name="priv"
            >
              <Checkbox checked={checkAll} onChange={(e)=>{onCheckAll(e.target.checked)}}>选中全部</Checkbox>
              <Checkbox checked={expandAll} onChange={(e)=>{onExpandAll(e.target.checked)}}>展开全部</Checkbox>

            <Tree
              style={{marginTop:20}}
              treeData={treeData}
              // fieldNames={{title:"name", key:"path", children:"sub"}}
              checkable
              // showLine={{showLeafIcon:true}}
              showLine={true}
              // defaultExpandAll={expandAll}
              // autoExpandParent={true}
              // blockNode={false}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              // onSelect={onSelect}
              // selectedKeys={selectedKeys}
            />
            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
