import React, { useState,useEffect,useRef }  from 'react';
import { Button, Dropdown, Space, Tag,Popconfirm,Switch,Row, Col,Form,Input  } from 'antd';
import { EllipsisOutlined, PlusOutlined,EyeOutlined,EyeInvisibleOutlined,EditOutlined,DeleteOutlined,LockOutlined,UserOutlined } from '@ant-design/icons';
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import EditModel from './components/edit';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

// type GithubIssueItem = {
//   url: string;
//   id: number;
//   number: number;
//   title: string;
//   labels: {
//     name: string;
//     color: string;
//   }[];
//   state: string;
//   comments: number;
//   created_at: string;
//   updated_at: string;
//   closed_at?: string;
// };
// "cid": "分类ID",
// "ctime": "创建时间",
// "name": "分类名称",
// "pid": "父级ID",
// "key": "关键字/编号",
// "status": "状态 0：隐藏 1：正常",
// "level": "等级 0：父级 1：一级 2：二级"
var tableData = [
  {
    "user": "张三",
    "type": "编辑",
    "action": "案例管理",
    "time": "2023-11-01 10:01:20",
  },
  {
    "user": "张三",
    "type": "编辑",
    "action": "案例管理",
    "time": "2023-11-01 10:01:20",
  },
  {
    "user": "张三",
    "type": "编辑",
    "action": "案例管理",
    "time": "2023-11-01 10:01:20",
  },
  {
    "user": "张三",
    "type": "编辑",
    "action": "案例管理",
    "time": "2023-11-01 10:01:20",
  },

]

export default () => {
  const actionRef = useRef();
  const [form] = Form.useForm();

  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const [catList, setCatList] = useState([]);

  const closeModal = () =>{
    setShowModal(false);
  }

  const onChangeStatus = (cid, checked) =>{
    console.log(cid,checked)
  }

  const onFinish = async(value)=>{
    // console.log(value);
    // console.log(bChecked);
    // localStorage.removeItem('token');

    // if(bChecked){
    //   localStorageSet("loginfo", encrypt(JSON.stringify({"account":value.account, "passwd":value.passwd})));
    // }
    // else{
    //   localStorage.removeItem("loginfo");
    // }
    // try {
    //     return await getApiData('A', 'A_login', value).then((res) => {
    //       console.log('A_login-res-----------------', res);
    //       var flag = checkLogin('A', res.data)
    //       if (flag) {
    //         localStorage.setItem('token',res.data.d.token);
    //         localStorageSet("UserInfo", {id:res.data.d.uid, account:value.account});
    //         window.location.href='/#/admin/dashboard';
    //         // props.history.push({
    //         //     pathname: '/admin',
    //         //   });
    //       }
    //     });
    //   } catch (error) {
        
    //     return false;
    //   }
}

  const columns = [
    {
      title: '操作人',
      dataIndex: 'user',
      // search:false,
      // valueType: 'indexBorder',
      // width: 100,
      ellipsis: true,
    },
    {
      title: '操作类型',
      dataIndex: 'type',
      // ellipsis: true,
      // search:false

      // copyable: true,
      // tip: '标题过长会自动收缩',
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: '此项为必填项',
      //     },
      //   ],
      // },
    },
    {
      title: '操作行为',
      dataIndex: 'action',
      // width: 150,
      search:false,
    },
    // {
    //   title: '创建时间',
    //   dataIndex: 'ctime',
    //   valueType: 'dateTime',
    //   width: 150,
    //   search:false,
    // },
    {
      disable: true,
      title: '操作时间',
      dataIndex: 'time',
      // width: 100,
      // search:false,
      // filters: true,
      // onFilter: true,
      // ellipsis: true,
      // valueType: 'select',
      // valueEnum: {
      //   "0": {
      //     text: '隐藏',
      //     status: 'Default',
      //   },
      //   "1": {
      //     text: '正常',
      //     status: 'Success',
      //     // disabled: true,
      //   },
      // },

    },
    // {
    //   title: '操作',
    //   valueType: 'option',
    //   width: 80,
    //   key: 'option',
    //   render: (text, record, _, action) => [
    //     <a
    //       key="editable"
    //       onClick={async() => {
    //         console.log('record ------------- ', record)
    //         console.log('action ------------- ', action)
    //         // action?.startEditable?.(record.id);
    //         setRowInfo(record);
    //         await waitTime(100);
    //         setShowModal(true);
    //       }}
    //     >
    //       <EditOutlined style={{backgroundColor:"rgb(24,188,156)",padding:"2px 3px",borderRadius:3,color:"white"}} />
    //     </a>,
    //   ],
    // },
  ];

  return (
    <div>
    <Row gutter={16}>
      <Col span={8} >
      <Form form={form}
            // className='loginForm'
            // name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            style={{border:"1px solid #ccc",padding:10, marginTop:25,marginLeft:10}}
        >
            <Form.Item
                label="用户名"
                name="account"
                // className='normal_input'
                rules={[
                  {required: true, message: '请输入邮箱或手机号',},
                  // {pattern: /^1\d{10}$/, message: '手机号格式错误'},

                  // {max: 12,message: '用户名最多12位',},
                  // {min: 3,message: '用户名至少3位',},
                  // {pattern:/^[a-zA-Z0-9_]+$/,message: '用户名必须是英文、字母或下划线',},

                    // {
                    // required: true,
                    // message: '请输入您的手机号！',
                    // },
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="密码"
                name="passwd"
                rules={[
                  {required: true,message: '密码必须6-30个字符',},
                  {max: 30,message: '密码最多30位',},
                  {min: 6,message: '密码至少6位',},
                  // {pattern:^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$,message: '密码必须包含字母和数字',},
                ]}
            >
            <Input.Password />
            </Form.Item>
            <Form.Item style={{textAlign:"center"}}>
            <Button type="primary" htmlType="submit" style={{marginRight:15}} >
                确定
            </Button>
            <Button shape="round" className="m-btn-white" onClick={closeModal}>
              取消
            </Button>
            </Form.Item>
        </Form>
      </Col>
      <Col span={16}>
          
        <ProTable
          headerTitle="操作日志"
          rowKey="cid"
          columns={columns}
          actionRef={actionRef}
          cardBordered
          size="small"
          request={async (params, sort, filter) => {
            console.log('params, sort, filter -------------------- ');
            console.log(params);
            console.log(sort);
            console.log(filter);
            // await waitTime(2000);
            // var tableData = [];
            // try {
            //   await getApiData('A_catelogList', params).then((res) => {
            //     console.log('A_catelogList-res-----------------', res);
            //     var flag = checkLogin(res.data)
            //     if (flag) {
            //       tableData = res.data.d.list;
            //     }
            //   });
            // } catch (error) {
              
            //   // return false;
            // }
            // setCatList(tableData);
            return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
            };
          }}
          // editable={{
          //   type: 'multiple',
          // }}
          // columnsState={{
          //   persistenceKey: 'pro-table-singe-demos',
          //   persistenceType: 'localStorage',
          //   onChange(value) {
          //     console.log('value: ', value);
          //   },
          // }}
          search={{
            labelWidth: 'auto',
          }}
          // options={{
          //   setting: {
          //     listsHeight: 200,
          //   },
          // }}
          // form={{
          //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
          //   syncToUrl: (values, type) => {
          //     if (type === 'get') {
          //       return {
          //         ...values,
          //         created_at: [values.startTime, values.endTime],
          //       };
          //     }
          //     return values;
          //   },
          // }}
          pagination={{
            pageSize: 10,
            onChange: (page) => console.log(page),
          }}
          // dateFormatter="string"
          // rowSelection={{
          //   // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
          //   // 注释该行则默认不显示下拉选项
          //   // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          //   defaultSelectedRowKeys: [],
          // }}
          // tableAlertRender={({
          //   selectedRowKeys,
          //   selectedRows,
          //   onCleanSelected,
          // }) => {
          //   console.log(selectedRowKeys, selectedRows);
          //   return (
          //     <Space size={24}>
          //       <span>
          //         已选 {selectedRowKeys.length} 项
          //         <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
          //           取消选择
          //         </a>
          //       </span>
          //       {/* <span>{`容器数量: ${selectedRows.reduce(
          //         (pre, item) => pre + item.containers,
          //         0,
          //       )} 个`}</span>
          //       <span>{`调用量: ${selectedRows.reduce(
          //         (pre, item) => pre + item.callNumber,
          //         0,
          //       )} 次`}</span> */}
          //     </Space>
          //   );
          // }}
          // tableAlertOptionRender={() => {
          //   return (
          //     <Space size={16}>
          //       <a><EyeOutlined />设为正常</a>
          //       <a><EyeInvisibleOutlined />设为隐藏</a>
          //     </Space>
          //   );
          // }}
          toolBarRender={() => [
            
          ]}
        />
      </Col>
    </Row>

    <EditModel
      showModal={showModal}
      closeModal={closeModal}
      rowInfo={rowInfo}
      catList={catList}
      refreshlist={()=>{actionRef.current?.reload();}}
    />
  </div>
  );
};