import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox, Space,Modal,Select,Radio,Cascader } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;


const groupOptions = [
  {
      value: '1',
      label: '初评',
      children: [
      {
          value: '11',
          label: '初评1组',
      },
      {
          value: '12',
          label: '初评2组',
      }
      ],
  },
  {
      value: '2',
      label: '复评',
      children: [
      {
          value: '21',
          label: '复评1组',
      },
      {
          value: '22',
          label: '复评2组',
      }
      ],
  },
  {
      value: '3',
      label: '终评',
      children: [
      {
          value: '31',
          label: '终评1组',
      },
      ],
  },
];



const App =(props)=>{
    const {showModal, closeModal, rowInfo, judgegroupList, refreshlist} = props;
    console.log('1111111111111111111111111111111111111-', rowInfo)
    const [form] = Form.useForm();
    // const [judgegroupList, setJudgegroupList] = useState([]);
    const [loading2,setLoading] = useState(false);


    useEffect(() => {
      if(showModal){
        if(rowInfo.jid == undefined){
          form.resetFields();
        } else {
          for(let key in rowInfo)
            form.setFieldValue(key, rowInfo[key])
        }

      }else{
        form.resetFields();
      }

    }, [showModal]);
    

    // // 获取评委组列表
    // const getJudgegroupList = ()=>{
    //   if(showModal){
    //     try {
    //       getApiData('A', 'A_judgegroupList', {}).then((res) => {
    //         console.log('A_judgegroupList-res-----------------', res);
    //         var flag = checkLogin('A', res.data)
    //         var level = [{value:"", label:"--请选择--"}];
    //         if (flag) {
    //           for(let item of res.data.d.list){
    //             level.push({value:item.jid, label:item.name});
    //           }
    //           setJudgegroupList(level);
    //         }
    //       });
    //     } catch (error) {
          
    //       // return false;
    //     }

    //   }
    // }
  
    
    const onChangeGroup = (value) => {
      console.log(value);
    };
    
    // 提交
    const onFinish = async(value)=>{
      console.log(value);
      let fun = "";
      if(rowInfo.jid == undefined){
        fun = "A_addJudge";
      } else {
        fun = "A_editJudge";
        value.jid = rowInfo.jid;
      }

      try {
        setLoading(true);
        await getApiData('A', fun, value).then((res) => {
          setLoading(false);
          console.log(fun+'-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            message.success("操作成功");
            closeModal();
            refreshlist();
          }
        });
        } catch (error) {
          message.error("网络异常");
          setLoading(false);
          return false;
        }
  }

  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.jid ? '编辑' :'添加'}
        open={showModal}
        width="600px"
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[<Button  className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" loading={loading2} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>]}
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:4,offset:2}}
            style={{fontSize:12}}
            size="small"
            initialValues={{status:"1"}}
        >
            <Form.Item
                label="用户名"
                name="name"
                rules={[
                  {required: true, message: '请输入用户名',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="密码"
                name="passwd"
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="评委组"
                name="groupid"
                rules={[
                  {required: true, message: '请选择评委组',},
                ]}
            >
              <Select
                  placeholder="请选择"
                  // onChange={onProdChange}
                  // defaultValue={""}
                  // allowClear
                  // style={{width:"50%"}}
                  options={judgegroupList}
              />

              {/* <Cascader options={groupOptions} onChange={onChangeGroup} allowClear={false} defaultValue={['1', '12']} /> */}
            </Form.Item>
            <Form.Item
                label="是否激活"
                name="status"
            >
              <Radio.Group 
                // defaultValue={"1"} 
                style={{width:"80%"}} >
                <Space size={10}>
                <Radio value={"0"}>否</Radio>
                <Radio value={"1"}>是</Radio>
                </Space>
              </Radio.Group>

            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
