import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Image,Progress,InputNumber, Space,Modal,Select,Radio } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import UploadFile from '../../components/UploadFile';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const screenHeight = document.body.clientHeight;

const App =(props)=>{
    const {showModal, closeModal, rowInfo, notetypeList, refreshlist} = props;
    console.log('1111111111111111111111111111111111111-', rowInfo)
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState("");
    const [attpercent, setAttpercent] = useState(0);
    const [attpercentShow,setAttpercentShow] = useState(false);
    const [maxFree, setMaxFree] = useState(0);
    const [companylist, setCompanylist] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
      if(showModal){
        getAllCompany();
        if(rowInfo.nid == undefined){
          form.resetFields();
          setImageUrl("");
        } else {
          for (let key in rowInfo) {
            form.setFieldValue(key, rowInfo[key])
          }
          setImageUrl(rowInfo.qualification);
          getAllFee(rowInfo.companyid);
        }

      } else {
        form.resetFields();
        setImageUrl("");
        setAttpercent(0);
        setAttpercentShow(false);
        setMaxFree(0);
        setCompanylist([]);
      }
      
    }, [showModal]);

    
    // useEffect(() => {
    //   form.setFieldValue("qualification", imageUrl);
    // }, [imageUrl]);
    
      
    // 获取总费用和已开票金额汇总
    const getAllFee = async (companyid) =>{
      try {
        await getApiData('A', 'A_getAllFee', {companyid:companyid}).then((res) => {
          console.log('A_getAllFee-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            let v = res.data.d.allfee - res.data.d.notefee;
            if(rowInfo.fee)
              v += parseFloat(rowInfo.fee);
            setMaxFree(v);
          }
        });
        } catch (error) {
          
        }
    }
      
    // 获取公司列表
    const getAllCompany = async () =>{
      try {
        await getApiData('A', 'A_companyList', {page:1,pagesize:10000}).then((res) => {
          console.log('A_companyList-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            var list = [];
            for(let item of res.data.d.list){
              list.push({value:item.cid, label:"【"+item.id+"】"+item.cname});
            }
            setCompanylist(list);
          }
        });
        } catch (error) {
          
        }
    }

    // 选择开票公司
    const onChangeCompany = (value) => {
      console.log(`selected ${value}`);
      getAllFee(value);
    };

    // 提交
    const onFinish = async(value)=>{
      console.log(value);
      if(!imageUrl){
        message.warn("请上传营业执照");
        return;
      }
      value.qualification = imageUrl;

      let fun = "";
      if(rowInfo.nid == undefined){
        fun = "A_createNote";
      } else {
        fun = "A_noteEdit";
        value.nid = rowInfo.nid;
        // delete value.cuid;
        delete value.companyid;
      }
      
      try {
        setLoading(true);
        await getApiData('A', fun, value).then((res) => {
          setLoading(false);
          console.log(fun+'-res-----------------', res);
          var flag = checkLogin('A', res.data)
          if (flag) {
            message.success("操作成功");
            closeModal();
            refreshlist();
          }
        });
        } catch (error) {
          message.error("网络异常");
          setLoading(false);
          return false;
        }
    }
  return (
    <Modal 
        key="basicinfo"
        title={rowInfo.nid ? '编辑' :'添加'}
        open={showModal}
        width="700px"
        bodyStyle={{maxHeight:screenHeight*0.8,overflowY:"scroll"}}
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={[[<Button shape="round" className="m-btn-white" onClick={closeModal}>
        取消
        </Button>,
        <Button type="primary" shape="round" loading={loading} style={{ marginLeft:'20px',backgroundColor:"rgb(24,188,156)"}} onClick={()=>{form.submit()}}>
        确认
        </Button>]]}
        onCancel={closeModal}>
          <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="right"
            labelCol={{span:4,offset:2}}
            size="small"
            initialValues={{invoicetype:"1", invoiceform:"1", voucher:"2"}}
        >
            {/* <Form.Item
                label="用户ID"
                name="cuid"
            >
            <Input disabled />
            </Form.Item> */}
            <Form.Item
                label="开票公司"
                name="companyid"
                rules={[
                  {required: true, message: '请选择开票公司',},
                ]}
            >
              <Select
                showSearch
                placeholder="请选择"
                optionFilterProp="children"
                onChange={onChangeCompany}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={companylist}
                disabled={rowInfo.nid!=undefined}
              />
            </Form.Item>
            <Form.Item
                label="抬头"
                name="title"
                rules={[
                  {required: true, message: '请输入发票抬头',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="税号"
                name="taxcode"
                rules={[
                  {required: true, pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: '请检查税号',},
                ]}
            >
            <Input />
            </Form.Item>
            {/* <Form.Item
                label="银行"
                name="bank"
                rules={[
                  {required: true, message: '请输入银行',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="账户"
                name="bankaccount"
                rules={[
                  {required: true, pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: '请检查账户',},
                ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="地址"
                name="address"
                rules={[
                  {required: true, message: '请输入地址',},
                ]}
            >
            <Input />
            </Form.Item> */}
            {/* <Form.Item
                label="电话"
                name="phone"
                rules={[
                  {required: true, pattern: new RegExp(/^[0-9]+$/), message: '请检查电话',},
                ]}
            >
            <Input />
            </Form.Item> */}
            <Form.Item
                label="开票内容"
                name="content"
                rules={[
                  {required: true, message: '请选择开票内容',},
                ]}
            >
            <Select placeholder="请选择" options={notetypeList} />
            </Form.Item>
            <Form.Item
                label="发票类别"
                name="invoicetype"
            >
            <Select>
              <Option value="1">增值税普通发票</Option>
              <Option value="2">增值税专用发票</Option>
            </Select>
            </Form.Item>
            <Form.Item label="开票金额："  style={{textAlign:"left", marginBottom:0}}>
              <Space direction="horizontal">
                <Form.Item name="fee" style={{width:"80%"}}
                rules={[
                  {required: true, message: '请输入开票金额',},
                ]}
                >
                    <InputNumber min={1} max={maxFree}  style={{width:"100%"}} />
                </Form.Item>
                <Form.Item >
                <span style={{color:"red"}}>最大开票金额：{maxFree}</span>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item
                label="发票形式"
                name="invoiceform"
                style={{textAlign:"left"}}
            >
            <Select>
              <Option value="1">电子发票</Option>
              {/* <Option value="2">纸质发票</Option> */}
            </Select>
            </Form.Item>

            {/* <Form.Item
                label="收件人"
                name="recipient"
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="快递地址"
                name="postaddress"
            >
            <Input />
            </Form.Item>
            <Form.Item
                label="收件人电话"
                name="recipientphone"
            >
            <Input />
            </Form.Item> */}
            <Form.Item
                label="付款凭证"
                name="voucher"
            >
            <Select>
              <Option value="1">不需要</Option>
              <Option value="2">付款说明</Option>
              <Option value="3">合作协议</Option>
            </Select>
            </Form.Item>
            <Form.Item
                label="营业执照"
                name="qualification"
                // rules={[
                //   {required: true, message: '请上传营业执照',},
                // ]}
            >
              {imageUrl ? 
                <Image
                  height={50}
                  src={imageUrl}
                /> : ""
              }
              <UploadFile 
                from = {"A"}
                upButton = {<Button style={{marginLeft:20}}>上传</Button>}
                uploadFileType = {".png, .jpg, .jpeg, .gif"}
                uploadFileSize = {5*1024*1024}
                setpercent = {setAttpercent}
                setPercentShow = {setAttpercentShow}
                setFileUrl = {setImageUrl}
              />
              <Progress percent={attpercent} strokeWidth={6} style={{top:5,display:attpercentShow?'block':'none'}}/>

            </Form.Item>

        </Form>
        </Modal>
  )
}
export default App
